import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import Breadcrumbs from "components/Breadcrumbs"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import {
  HeaderBreadcrumbInfo,
  HeaderContent,
} from "components/HeaderInfo/HeaderInfo"
import { useTippyLayout } from "components/TippyCustomzie"
import { PATH } from "constants/path"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { formatDate, formatDateForConversationMessage } from "helpers/utils"
import LabelHeader, {
  getContentLabelStyleProps,
} from "../molecules/LabelHeader"
import { HeaderProps } from "../types"
import { getWorkerLocalStorageData } from "workers/utils"

const ProjectHeader = (props: HeaderProps) => {
  const { projectDetail } = props
  const { isMobile } = useWindowSize()
  const contentLabelStyleProps = getContentLabelStyleProps(!isMobile)
  const { projectEncryptionKeys } = getWorkerLocalStorageData()

  const showProjectInfo = useBoolean()
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()

  const listBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: "Projects",
      path: PATH.projects,
    },
    {
      name: projectDetail?.name,
    },
  ]

  const renderProjectInfo = () => (
    <div className="flex flex-col md:flex-row">
      <LabelHeader
        {...contentLabelStyleProps}
        title="Project ID:"
        label={projectDetail.code}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Created:"
        label={formatDate(projectDetail?.created_at, "DD MMM yyyy")}
      />
      <LabelHeader
        {...contentLabelStyleProps}
        title="Last update:"
        label={
          projectDetail?.updated_at
            ? `${formatDateForConversationMessage(projectDetail.updated_at)}`
            : "No update"
        }
        isBorderRight={projectEncryptionKeys[projectDetail.id] ? true : false}
      />
    </div>
  )

  const renderProjectContent = () =>
    isMobile ? (
      <TippyLayout
        visible={showProjectInfo.value}
        headerContent={
          <HeaderContent onClick={() => showProjectInfo.setValue(false)} />
        }
        mainContent={renderProjectInfo()}
      >
        <LabelHeader
          title="Project Info"
          isBorderRight={projectEncryptionKeys[projectDetail.id] ? true : false}
          onClickTitle={() => {
            showProjectInfo.setValue(true)
          }}
        />
      </TippyLayout>
    ) : (
      renderProjectInfo()
    )
  return (
    <HeaderBreadcrumbInfo>
      <Breadcrumbs
        listBreadcrumbs={listBreadcrumbs}
        className="hidden md:flex mr-1"
        overflow
      />
      <div className="flex items-center justify-center">
        {renderProjectContent()}
        {projectEncryptionKeys[projectDetail.id] ? (
          <ActivityLogDefault
            model={MODEL_ACTIVITY_LOG.PROJECTS}
            idRelation={projectDetail.id}
          />
        ) : null}
      </div>
    </HeaderBreadcrumbInfo>
  )
}

export default ProjectHeader
