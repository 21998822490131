import React from "react"
import { showImageUrl } from "helpers/utils"

interface Props {
  file: string
  color: string
  active: boolean
  isSvgContent?: boolean
}
const isEqualProps = (prevProps: Props, nextProps: Props) => {
  return (
    JSON.stringify(prevProps.file) === JSON.stringify(nextProps.file) &&
    JSON.stringify(prevProps.active) === JSON.stringify(nextProps.active)
  )
}

const SvgLayerCard = React.memo((props: Props) => {
  const { active, file, isSvgContent } = props
  // if (isSvgContent) {
  //   return (
  //     <svg
  //       className={`w-full h-full absolute ${
  //         active ? "opacity-40" : "opacity-0"
  //       }`}
  //       style={{ position: "absolute", top: 0 }}
  //       dangerouslySetInnerHTML={{ __html: file }}
  //     />
  //   )
  // }
  return (
    <img
      src={file}
      alt="icon"
      className={`w-full h-full absolute ${
        active ? "opacity-40" : "opacity-0"
      }`}
    />
  )
  // return (
  //   <svg
  //     className={`w-full h-full absolute ${
  //       active ? "opacity-40" : "opacity-0"
  //     }`}
  //     style={{ position: "absolute", top: 0 }}
  //     dangerouslySetInnerHTML={{ __html: file }}
  //   />
  // )
}, isEqualProps)

export default SvgLayerCard
