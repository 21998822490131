import ActionIconComment from "components/ActionIcon/ActionIconComment"
import Button from "components/Button/Button"
import { STATUS_BUILD } from "components/Status/types"
import { TippyCustomzie, useTippyLayout } from "components/TippyCustomzie"
import { useWindowSize } from "helpers/hooks"
import { checkPermissionPage } from "helpers/utils"
import { ProjectComponentDetail } from "pages/project-component/types"
import React from "react"
import CommentCard from "../organisms/CommentCard"
import { BuildHistoryDetail, TAB_URL_PROJECT_COMPONENT } from "../types"
import ActionIconHistory from "./ActionIconHistory"
import ShowViewLinkBuildCard from "./ShowViewLinkBuildCard"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { MESSENGER_NOTIFICATION } from "constants/messenger"

interface Props {
  historyDetail: BuildHistoryDetail
  titlePage: string
  isViewLinkedBuild: boolean
  onViewLinedBuilds: () => void
  handleDeleteComponent: () => void
  archiveProject: boolean
  archiveBuild: boolean
  viewOnlyShare: boolean
  archiveComponent: boolean
  isListActionHistory: boolean
  isDisableEditHistory: boolean
  isDisableRevertHistory: boolean
  isDisableMergeHistory: boolean
  isDisableTranscendHistory: boolean
  disableAllButton: boolean
  openModalCommit: () => void
  openModalCreate: () => void
  openModalRevert: () => void
  openModalMerge: () => void
  openModalTranscend: () => void
  isNotDownload?: boolean
  onDownloadFile: () => void
  showTippyComment: {
    value: boolean
    setValue: (newValue: boolean) => void
  }
  idProjectBuildComponent: string
  projectComponentDetail: ProjectComponentDetail
  isReadComponent: boolean
  isActiveLinedBuild: boolean
  projectComponentHistoryId: string
  handleClickTippyComment: (event) => void
  handleChangeHistoryTree: (originIsHistory: string) => void
  isDeleted?: boolean
  commitButtonStage: {
    isActive: boolean
    tooltipHelper: string
  }
}
const ListActionHistoryTreeComponentCard = (props: Props) => {
  const {
    historyDetail,
    titlePage,
    isViewLinkedBuild,
    archiveComponent,
    archiveProject,
    archiveBuild,
    viewOnlyShare,
    onViewLinedBuilds,
    handleDeleteComponent,
    openModalCommit,
    openModalCreate,
    isDisableEditHistory,
    isDisableRevertHistory,
    isDisableMergeHistory,
    openModalRevert,
    openModalMerge,
    disableAllButton,
    isDisableTranscendHistory,
    openModalTranscend,
    isNotDownload,
    onDownloadFile,
    showTippyComment,
    idProjectBuildComponent,
    projectComponentDetail,
    isReadComponent,
    handleClickTippyComment,
    isActiveLinedBuild,
    projectComponentHistoryId,
    handleChangeHistoryTree,
    isDeleted,
    commitButtonStage,
  } = props

  const { isMobile } = useWindowSize()
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()

  const renderButtonDownload = () => {
    if (!isDeleted && !isNotDownload) {
      return (
        <ActionIconHistory
          icon="download"
          tooltip="Download"
          onClick={onDownloadFile}
          styleIcon={{
            marginRight: 0,
          }}
        />
      )
    }
    return null
  }

  const renderButtonDelete = () => {
    if (!isDeleted && historyDetail.status === STATUS_BUILD.DRAFT) {
      return (
        <Button
          title="Delete Draft"
          colorBtn="white"
          onClick={handleDeleteComponent}
          disabled={checkPermissionPage({
            project: archiveProject,
            viewShare: viewOnlyShare,
            component: archiveComponent,
            build: archiveBuild,
          })}
          styleButton={{
            marginLeft: 8,
          }}
          sizeBtn="small"
        />
      )
    }
    return null
  }

  const renderButtonMergeOrTranscend = () => {
    if (isDeleted) {
      return null
    }
    if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
      return (
        <ActionIconHistory
          icon="merge"
          tooltip="Merge"
          disabled={
            checkPermissionPage({
              project: archiveProject,
              viewShare: viewOnlyShare,
              component: archiveComponent,
              build: archiveBuild,
            }) || isDisableMergeHistory
          }
          onClick={openModalMerge}
        />
      )
    }
    return (
      <>
        <ActionIconHistory
          icon="transcend"
          tooltip={
            archiveProject || archiveComponent
              ? MESSENGER_NOTIFICATION.PERMISSION_COMPONENT
              : "Duplicate"
          }
          disabled={
            checkPermissionPage({
              project: archiveProject,
              viewShare: viewOnlyShare,
              component: archiveComponent,
              build: archiveBuild,
            }) ||
            isDisableTranscendHistory ||
            disableAllButton
          }
          onClick={openModalTranscend}
        />
        <ActionIconHistory
          icon="merge"
          tooltip={
            archiveProject || archiveComponent
              ? MESSENGER_NOTIFICATION.PERMISSION_COMPONENT
              : "Merge"
          }
          disabled={
            checkPermissionPage({
              project: archiveProject,
              viewShare: viewOnlyShare,
              component: archiveComponent,
              build: archiveBuild,
            }) || isDisableMergeHistory
          }
          onClick={openModalMerge}
        />
      </>
    )
  }

  const renderContent = () => (
    <CommentCard
      isOpen={showTippyComment.value}
      onClose={() => showTippyComment.setValue(false)}
      idComponent={
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? idProjectBuildComponent
          : projectComponentDetail.id
      }
      titlePage={titlePage}
      urlComponent={
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? `project-build-component/${idProjectBuildComponent}/comment/get-list`
          : `project-component/${projectComponentDetail.id}/comment`
      }
      projectComponentHistoryId={projectComponentHistoryId}
      handleChangeHistoryTree={handleChangeHistoryTree}
      projectBuildComponentId={idProjectBuildComponent}
      viewOnly={isDeleted}
    />
  )

  const renderMessage = () => {
    return isMobile ? (
      <TippyLayout
        visible={showTippyComment.value}
        mainContent={renderContent()}
      >
        <ActionIconComment
          handleClickTippyComment={handleClickTippyComment}
          isReadComponent={isReadComponent}
        />
      </TippyLayout>
    ) : (
      <TippyCustomzie
        containerClass="component-card-menu"
        placement="bottom-end"
        interactive
        arrow={false}
        animation="scale"
        visible={showTippyComment.value}
        onClickOutside={() => showTippyComment.setValue(false)}
        content={renderContent()}
        allowHTML
      >
        <ActionIconComment
          handleClickTippyComment={handleClickTippyComment}
          isReadComponent={isReadComponent}
        />
      </TippyCustomzie>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <p
          className="font-normal"
          style={{
            fontSize: 13,
            lineHeight: "22px",
            color: "#7A7A7A",
          }}
        >
          Current version:
          <span
            className="font-semibold ml-2"
            style={{
              color: "#333333",
            }}
          >
            {historyDetail.code}
          </span>
        </p>
        <div className="bg-[#e4e4e4] h-[16px] w-[1px] mx-2"></div>

        <ShowViewLinkBuildCard
          status={historyDetail.status}
          titlePage={titlePage}
          isViewLinkedBuild={isViewLinkedBuild}
          onViewLinedBuilds={onViewLinedBuilds}
          isActiveLinedBuild={isActiveLinedBuild}
        />
        <div className="hidden md:block">{renderButtonDelete()}</div>
      </div>

      <div className=" flex items-center">
        <div className="hidden md:flex items-center">
          {!isDeleted ? (
            <React.Fragment>
              <ActionIconHistory
                icon="commit"
                tooltip={
                  archiveProject || archiveComponent
                    ? MESSENGER_NOTIFICATION.PERMISSION_COMPONENT
                    : commitButtonStage.isActive ||
                      historyDetail.status === STATUS_BUILD.COMMITTED
                    ? "Commit"
                    : commitButtonStage.tooltipHelper
                }
                onClick={() => {
                  if (
                    !commitButtonStage.isActive &&
                    historyDetail.status !== STATUS_BUILD.COMMITTED
                  ) {
                    return toast(
                      <LabelNotificationPage
                        messenger={commitButtonStage.tooltipHelper}
                        type="warning"
                      />
                    )
                  }
                  openModalCommit()
                }}
                disabled={
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                    component: archiveComponent,
                    build: archiveBuild,
                  }) || !commitButtonStage.isActive
                }
              />
              <ActionIconHistory
                icon="edit"
                tooltip={
                  archiveProject || archiveComponent
                    ? MESSENGER_NOTIFICATION.PERMISSION_COMPONENT
                    : (isDisableEditHistory || disableAllButton) &&
                      historyDetail.status !== STATUS_BUILD.DRAFT
                    ? historyDetail.branch.indexOf("BD-") > -1 &&
                      historyDetail.tree_version_type
                      ? "Cannot edit on a branch commit."
                      : "Can only edit on the latest commit."
                    : "Edit"
                }
                onClick={openModalCreate}
                disabled={
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                    component: archiveComponent,
                    build: archiveBuild,
                  }) ||
                  isDisableEditHistory ||
                  disableAllButton
                }
              />
              <ActionIconHistory
                icon="revert"
                tooltip={
                  archiveProject || archiveComponent
                    ? MESSENGER_NOTIFICATION.PERMISSION_COMPONENT
                    : "Revert"
                }
                disabled={
                  checkPermissionPage({
                    project: archiveProject,
                    viewShare: viewOnlyShare,
                    component: archiveComponent,
                    build: archiveBuild,
                  }) ||
                  isDisableRevertHistory ||
                  disableAllButton
                }
                onClick={openModalRevert}
              />
              {renderButtonMergeOrTranscend()}

              <div
                style={{
                  height: 16,
                  width: 1,
                  background: "#E4E4E4",
                  marginRight: 14,
                }}
              ></div>
            </React.Fragment>
          ) : null}
          {renderButtonDownload()}
        </div>
        {/* {renderMessage()} */}
      </div>
    </div>
  )
}
export default ListActionHistoryTreeComponentCard
