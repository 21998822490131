import { Avatar } from "components/Avatar"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import SelectTippy from "components/Select/SelectTippy"
import Typography from "components/Typography"
import { EVENT } from "constants/events"
import { useBoolean } from "helpers/hooks"
import { umamiTracking } from "helpers/utils"
import { isFunction, isUndefined } from "lodash"
import DotCard from "pages/conversations/atoms/DotCard"
import ActionIconHistory from "pages/project-component-detail/molecules/ActionIconHistory"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import {
  deleteRemoveUserShareInProjectMiddleware,
  postCopyLinkProjectMiddleware,
  setRoleInProjectMiddleware,
} from "../services/api"
import {
  defaultSelectedRole,
  ItemUserInvitedProps,
  SelectedRole,
} from "../types"
import Tippy from "@tippyjs/react"
import { ReactComponent as IconCheckedActive } from "assets/images/icons/icon-checked-build-active.svg"

export const ItemUserInvited = (props: ItemUserInvitedProps) => {
  const { data, originRoleOptions, projectId, getUsersInProject } = props

  const [selectedRole, setSelectedRole] =
    useState<SelectedRole>(defaultSelectedRole)
  const [roleOptions, setRoleOptions] = useState<SelectedRole[]>([])
  const isLoading = useBoolean(false)
  useEffect(() => {
    const defaultRole = originRoleOptions.find(
      (i) => String(i.value) === String(data.role)
    )
    if (!isUndefined(defaultRole)) {
      setSelectedRole(defaultRole)
    }
    const newRoleOptions = originRoleOptions.map((el) => {
      let newLabel = el.label
      if (el.label === "Editor") {
        newLabel = "Can edit"
      }
      if (el.label === "Viewer") {
        newLabel = "Can view"
      }
      return {
        ...el,
        label: newLabel,
      }
    })
    newRoleOptions.push({
      label: "Remove user",
      value: "4",
    })
    setRoleOptions(newRoleOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.role, JSON.stringify(originRoleOptions)])

  const handleChangeSelect = (selected: any) => {
    // Remove user
    if (Number(selected.value) === 4) {
      handleRemoveUser(Number(selected.value))
      return
    }
    handleChangeRole(selected)
  }

  const handleChangeRole = async (oldSelected: any) => {
    const bodyData = {
      email: data.email,
      role: Number(oldSelected.value),
    }
    isLoading.setValue(true)
    setRoleInProjectMiddleware(
      projectId,
      bodyData,
      true,
      (type: STATUS_RESPONSE, newMessenger: string) => {
        toast(<LabelNotificationPage messenger={newMessenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getUsersInProject(bodyData)
          setSelectedRole(oldSelected)
        }
      }
    )
    umamiTracking(EVENT.PROJECT.SHARE)
  }
  const handleRemoveUser = (newRole: number) => {
    if (!projectId || !data.user_id) {
      return
    }
    isLoading.setValue(true)
    deleteRemoveUserShareInProjectMiddleware(
      projectId,
      data.user_id,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getUsersInProject({
            email: data.email,
            role: newRole,
          })
        }
      }
    )
  }
  const handleResendInvitee = () => {
    if (!projectId || !data.user_id) {
      return
    }
    isLoading.setValue(true)
    const bodyData = {
      email: data.email,
      role: data.role,
    }

    setRoleInProjectMiddleware(
      projectId,
      bodyData,
      false,
      (type: STATUS_RESPONSE, messenger: string) => {
        const newMessage =
          type === STATUS_RESPONSE.SUCCESS
            ? "Resend invite successfully!"
            : messenger
        toast(<LabelNotificationPage messenger={newMessage} type={type} />)
        isLoading.setValue(false)
      }
    )
  }
  const copyLink = () => {
    if (!projectId || !data.user_id) {
      return
    }
    isLoading.setValue(true)
    const bodyData = {
      email: data.email,
      role: data.role,
    }
    postCopyLinkProjectMiddleware(
      projectId,
      bodyData,
      false,
      (type: STATUS_RESPONSE, messenger: string, link?: string) => {
        if (link && isFunction(navigator?.clipboard?.writeText)) {
          navigator.clipboard.writeText(link)
        }

        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
      }
    )
  }
  const renderSentInvite = () => (
    <div className="flex items-center">
      <p className="font-normal ml-8 md:ml-[10px] leading-[22px] text-[13px] text-brown">
        Invite sent
      </p>

      <DotCard />
      <p
        className="font-normal cursor-pointer hover:underline leading-[22px] text-[13px] text-blue"
        onClick={handleResendInvitee}
      >
        Resend invite
      </p>
      {data.accepted ? (
        <>
          <DotCard />
          <Tippy
            className="custom-tippy-menu-left"
            placement="top"
            content={
              <span
                className="hidden md:block font-normal"
                style={{
                  fontSize: 13,
                  lineHeight: "22px",
                }}
              >
                Accepted
              </span>
            }
          >
            <IconCheckedActive />
          </Tippy>
        </>
      ) : null}
      <ActionIconHistory
        classIcon="hidden md:block"
        icon="copy-link"
        tooltip="Copy link"
        styleIcon={{
          marginLeft: 12,
          marginRight: 0,
        }}
        onClick={copyLink}
      />
    </div>
  )

  return (
    <div className="flex flex-col md:flex-row">
      <div className="flex flex-1 items-center justify-between">
        <div className="flex items-center">
          <Avatar
            width="24px"
            height="24px"
            name={
              typeof data?.is_new_user === "boolean" &&
              data?.is_new_user === false
                ? "?"
                : data?.fullname
            }
            avatar={data?.avatar}
            textcolor="white"
            contentsize="11px"
            containerclass={
              typeof data?.is_new_user === "boolean" &&
              data?.is_new_user === false
                ? "bg-gray-custom"
                : undefined
            }
          />
          <div className="flex items-center ml-2">
            <Typography
              textClass="font-semibold"
              style={{
                color: "#111111",
                fontSize: 13,
                lineHeight: "22px",
                fontWeight: 600,
              }}
            >
              {data?.fullname || "N/A"}
            </Typography>
            <div className="hidden md:block">{renderSentInvite()}</div>
          </div>
        </div>
        <SelectTippy
          optionsSelect={roleOptions}
          activeSelect={selectedRole.value}
          handleChange={handleChangeSelect}
          offsetCustom={[0, 5]}
          placementCustom="bottom-end"
        />
      </div>

      <div className="block md:hidden">{renderSentInvite()}</div>
    </div>
  )
}
