import { ReactComponent as IconLock } from "assets/images/icons/icon-lock.svg"
import { isUndefined } from "lodash"
interface Props {
  type?: "project" | "build" | "component"
  isOwner?: boolean
  styleRoot?: React.CSSProperties
  conversationCodes?: string[]
}

export const EncryptedDataWarning = (props: Props) => {
  const { type, isOwner, styleRoot, conversationCodes } = props
  const renderBody = () => {
    if (!isUndefined(conversationCodes) && conversationCodes.length) {
      return (
        <div className="flex flex-col items-center justify-center mt-[24px]">
          <p className="text-[14px] text-[#EA4545] leading-[24px] mt-[24px] text-center font-semibold mb-[12px]">
            {`This ${conversationCodes
              .map((el) => el)
              .join(", ")} is encrypted`}
            .
          </p>

          <p className="text-[14px] text-[#111111] leading-[24px]">
            {`Ask the ${
              isOwner ? "Tracelium Admin" : "Owner"
            } for access to view it.`}
          </p>
        </div>
      )
    }
    if (!isUndefined(type)) {
      return (
        <>
          <p className="text-[14px] text-[#EA4545] leading-[24px] mt-[24px] font-semibold mb-[12px]">
            {`This ${type} is encrypted`}.
          </p>
          <p className="text-[14px] text-[#111111] leading-[24px]">
            {`Ask the ${
              isOwner ? "Tracelium Admin" : "Owner"
            } for access to view it.`}
          </p>
        </>
      )
    }
  }

  return (
    <div
      className="h-full"
      style={{
        ...styleRoot,
      }}
    >
      <div className="flex flex-col items-center justify-center h-full">
        <IconLock />
        {renderBody()}
      </div>
    </div>
  )
}
