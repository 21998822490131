import { STATUS_BUILD } from "components/Status/types"
import { GetUsersInProjectProp } from "pages/project-component/types"
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
} from "date-fns"
import { InviteeBuilComponentDetail } from "pages/project-build/project-build.type"
import { UserInfo } from "pages/auth/types"
import { STATUS_PROJECT_ROLE } from "pages/projects/types"

export enum MENU_TAB_CONVERSATION {
  TO_ME = "To me",
  CC = "Cc to me",
  FROM_ME = "From me",
  OTHERS = "Others",
}
export const UNREAD_KEY_BY_TAB = {
  [MENU_TAB_CONVERSATION.TO_ME]: "unread_to_me",
  [MENU_TAB_CONVERSATION.CC]: "unread_cc_to_me",
  [MENU_TAB_CONVERSATION.FROM_ME]: "unread_from_me",
  [MENU_TAB_CONVERSATION.OTHERS]: "unread_others",
}
export const MENU_TAB_KEY = {
  [MENU_TAB_CONVERSATION.CC]: "cc-to-me",
  [MENU_TAB_CONVERSATION.TO_ME]: "to-me",
  [MENU_TAB_CONVERSATION.FROM_ME]: "from-me",
  [MENU_TAB_CONVERSATION.OTHERS]: "others",
}
export const MENU_TAB_CONVERSATION_BASE_ON_KEY = {
  [MENU_TAB_KEY[MENU_TAB_CONVERSATION.TO_ME]]: MENU_TAB_CONVERSATION.TO_ME,
  [MENU_TAB_KEY[MENU_TAB_CONVERSATION.CC]]: MENU_TAB_CONVERSATION.CC,
  [MENU_TAB_KEY[MENU_TAB_CONVERSATION.FROM_ME]]: MENU_TAB_CONVERSATION.FROM_ME,
  [MENU_TAB_KEY[MENU_TAB_CONVERSATION.OTHERS]]: MENU_TAB_CONVERSATION.OTHERS,
}

export interface SelectDefaultConversation {
  key: string
  value: string
}
export const MenuTabConversation: string[] = [
  MENU_TAB_CONVERSATION.TO_ME,
  MENU_TAB_CONVERSATION.CC,
  MENU_TAB_CONVERSATION.FROM_ME,
  MENU_TAB_CONVERSATION.OTHERS,
]
export interface UnreadBuild {
  unread_my_build: number
  unread_others_build: number
}
export interface UnreadConversation {
  unread_to_me: number
  unread_cc_to_me: number
  unread_from_me: number
  unread_others: number
}
export interface CustomStatus {
  id: string
  name: string
  color: string
}
export enum STATUS_CONVERSATION {
  OPEN = "OPEN",
  ACCEPTED = "ACCEPTED",
  CLOSED = "CLOSED",
  REMOVED = "REMOVED",
}
export enum CONVERSATION_ROLE {
  INVITEE = 1,
  CC = 2,
  CONTRIBUTOR = 3,
  VIEWER = 4,
  INVITER = 5,
}

export interface ConversationDetail {
  project_code: string
  project_build_code: string
  project_build_status: STATUS_BUILD
  created_at: string
  created_by: string
  updated_at: string
  is_favorite: boolean
  from: string
  id: string
  status: STATUS_CONVERSATION
  code: string
  invitee: {
    user_id: string
    role: number
    name: string
    permission: string
    avatar: string
    id: string
    email: string
    status: number
    in_production_status: number
  }
  message: {
    content: string
    created_at: string
    owner: string
    showBackup?: boolean
    files?: {
      file_name: string
      file: string
      id: string
    }[]
  }
  owner: {
    user_id: string
    role: number
    name: string
    permission: string
    avatar: string
    id: string
    email: string
  }
  project_build_id: string
  read_users: string[]
  components: InviteeBuilComponentDetail[]
  custom_status: CustomStatus | undefined
  is_read: boolean
  is_archived_by_owner: boolean
  is_archived_by_me: boolean
  to: string
  additional_count: number
  cc: string[]
  role: CONVERSATION_ROLE
  project_id: string
  invitee_status: number
  tab: string
  draftMessage: string
  draftAttachments: number
  is_hidden: boolean
}

export interface BuildConversationDetail {
  id: string
  name: string
  code: string
  status: STATUS_BUILD
  project_id: string
  project_code: string
  project_name: string
  updated_by: string
  updated_at: string
  invitee_updated_at: string
  is_favorite: boolean
  is_read: boolean
  users: GetUsersInProjectProp[]
  components: string[]
  is_archived: number | undefined
  text: string
  project_role: string
  is_archived_invitee: boolean
  custom_status: CustomStatus | undefined
  notes: {
    note: string
    files: { file: string; file_name: string; url: string }[]
  }
  tab: string
}
export interface BuildInviteesConversationDetail {
  created_at: string
  created_by: string
  from: string
  id: string
  code: string
  invitee: {
    user_id: string
    role: number
    name: string
    permission: string
    avatar: string
    id: string
    email: string
    status: number
    in_production_status: number
    is_sent: boolean
  }
  message: {
    content: string
    created_at: string
    owner: string
  }
  owner: {
    user_id: string
    role: number
    name: string
    permission: string
    avatar: string
    id: string
    email: string
  }
  project_build_id: string
  read_users: string[]
  components: InviteeBuilComponentDetail[]
  cc: string[]
  status: STATUS_CONVERSATION
  is_read: boolean
  to: string
}

export interface ConversationMessagesDetail {
  components: InviteeBuilComponentDetail[]
  invitee: {
    created_at: string
    created_by: string
    email: string
    id: string
    is_sent: number
    name: string
    project_build_component_ids: string
    project_build_id: string
    status: number
    user_id: string
    avatar: string
    in_production_status: number
    accepted?: boolean
  }
  encryptedKey?: string
  owner: UserInfo
  messages: ConversationMessagesDetailItem[]
  conversation_code: string
  total_message: number
  is_not_yet_commented_by_users: boolean
  showBackup?: boolean
  is_owner?: boolean
  is_conversation_un_active?: boolean
}
export const BUILD_INVITEE_DETAIL_STATUS = {
  1: "OPEN",
  2: "ACCEPTED",
  3: "CLOSED",
}
export interface ConversationMessagesDetailItem {
  id: string
  content: string
  originContent: string
  files: {
    file_name: string
    file: string
  }[]
  created_at: string
  created_by: string
  user: {
    avatar: string
    id: string
    fullname: string
    email: string
  }
  is_public_to_conversation: number
  conversation_id: string
  is_notification: number
  commented_from: {
    project_build_component_id: string
    project_component_code: string
    project_component_history_code: string
    project_component_history_id: string
    project_component_id: string
    project_component_type: string
  }
}
export interface NoteFile {
  file: string
  file_name: string
  url: string
}
export interface Note {
  note: string
  files: NoteFile[]
  updated_at: string
}
export const emptyNote: Note = {
  note: "",
  files: [],
  updated_at: "",
}

export const emptyBuildInviteesConversationDetail: BuildInviteesConversationDetail =
  {
    created_at: "",
    created_by: "",
    from: "",
    id: "",
    code: "",
    invitee: {
      user_id: "",
      role: 0,
      name: "",
      permission: "",
      avatar: "",
      id: "",
      email: "",
      status: 1,
      in_production_status: 1,
      is_sent: true,
    },
    message: {
      content: "",
      created_at: "",
      owner: "",
    },
    owner: {
      user_id: "",
      role: 0,
      name: "",
      permission: "",
      avatar: "",
      id: "",
      email: "",
    },
    status: STATUS_CONVERSATION.OPEN,
    cc: [],
    project_build_id: "",
    read_users: [],
    components: [],
    is_read: false,
    to: "",
  }

export const emptyConversationMessagesDetail: ConversationMessagesDetail = {
  components: [],
  invitee: {
    created_at: "",
    created_by: "",
    email: "",
    id: "",
    is_sent: 0,
    name: "",
    project_build_component_ids: "",
    project_build_id: "",
    status: 0,
    user_id: "",
    avatar: "",
    in_production_status: 1,
  },
  owner: {
    avatar: "",
    company_name: "",
    email: "",
    fullname: "",
    id: "",
    is_verified: 0,
    phone_number: "",
    role_id: "",
    status: 0,
    address: "",
    has_new_shared_project: 0,
    has_agreed_latest_document: false,
    company_role_custom: "",
    company_role_id: "",
    is_phone_verified: 0,
  },
  messages: [],
  conversation_code: "",
  total_message: 0,
  is_not_yet_commented_by_users: true,
}
export const emptyBuildConversationDetail: BuildConversationDetail = {
  id: "",
  name: "",
  code: "",
  status: STATUS_BUILD.DRAFT,
  project_id: "",
  project_code: "",
  project_name: "",
  updated_by: "",
  updated_at: "",
  is_favorite: false,
  is_read: false,
  users: [],
  project_role: STATUS_PROJECT_ROLE.VIEWER,
  components: [],
  is_archived: undefined,
  is_archived_invitee: true,
  invitee_updated_at: "",
  text: "",
  custom_status: {
    id: "",
    name: "",
    color: "",
  },
  notes: {
    note: "",
    files: [],
  },
  tab: "",
}
export const emptyConversationDetail: ConversationDetail = {
  project_code: "",
  project_build_code: "",
  project_build_status: STATUS_BUILD.DRAFT,
  is_favorite: false,
  created_at: "",
  created_by: "",
  updated_at: "",
  from: "",
  id: "",
  status: STATUS_CONVERSATION.OPEN,
  code: "",
  invitee: {
    user_id: "",
    role: 0,
    name: "",
    permission: "",
    avatar: "",
    id: "",
    email: "",
    status: 1,
    in_production_status: 1,
  },
  message: {
    content: "",
    created_at: "",
    owner: "",
  },
  owner: {
    user_id: "",
    role: 0,
    name: "",
    permission: "",
    avatar: "",
    id: "",
    email: "",
  },
  project_build_id: "",
  read_users: [],
  components: [],
  is_read: false,
  is_archived_by_me: false,
  is_archived_by_owner: false,
  to: "",
  additional_count: 0,
  cc: [],
  custom_status: {
    id: "",
    name: "",
    color: "",
  },
  role: CONVERSATION_ROLE.INVITEE,
  project_id: "",
  invitee_status: 0,
  tab: MENU_TAB_KEY[MENU_TAB_CONVERSATION.CC],
  draftMessage: "",
  draftAttachments: 0,
  is_hidden: false,
}
export const emptyUnreadBuild: UnreadBuild = {
  unread_my_build: 0,
  unread_others_build: 0,
}
export const emptyUnreadConversation: UnreadConversation = {
  unread_to_me: 0,
  unread_cc_to_me: 0,
  unread_from_me: 0,
  unread_others: 0,
}
export const definedDateRanges = {
  startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfQuarter: startOfQuarter(new Date()),
  endOfQuarter: endOfQuarter(new Date()),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
}

export interface SelectionRangeDetail {
  key: string
  startDate: Date
  endDate: Date
}

export interface InviteeAddColumnDetail {
  key: string
  values: string[]
  idColumn: string
}

export interface ParamsBuildConversationProps {
  page: number
  pageSize: number
  filter?: any
}

export const GROUP_BY_OPTIONS = [
  {
    key: "latest_update",
    value: "Latest update",
  },
  {
    key: "oldest_update",
    value: "Oldest update",
  },
  {
    key: "build_status",
    value: "Build status",
  },
  {
    key: "custom_status",
    value: "My status",
  },
]

export const GROUP_BY_OPTIONS_KEY = {
  custom_status: "My status",
  build_status: "Build status",
  oldest_update: "Oldest update",
  latest_update: "Latest update",
}

export type ConversationShareKeys = {
  encryptedKey: string
  buildProjectEncryptedKey: string
  sharedUser: string
  role: string
  buildName: string
  accepted: any
  userPublicKey: string
}
export const TAB_BY_ROLE = {
  [CONVERSATION_ROLE.INVITEE]: MENU_TAB_KEY[MENU_TAB_CONVERSATION.TO_ME],
  [CONVERSATION_ROLE.CC]: MENU_TAB_KEY[MENU_TAB_CONVERSATION.CC],
  [CONVERSATION_ROLE.CONTRIBUTOR]: MENU_TAB_KEY[MENU_TAB_CONVERSATION.OTHERS],
  [CONVERSATION_ROLE.VIEWER]: MENU_TAB_KEY[MENU_TAB_CONVERSATION.OTHERS],
  [CONVERSATION_ROLE.INVITER]: MENU_TAB_KEY[MENU_TAB_CONVERSATION.FROM_ME],
}

export type ConversationKeyResponse = {
  data: {
    encryptedKey: string
    conversationId: string
  }[]
  statusCode: number
}
