import SimpleTable, { SimpleTableProps } from "components/Table/SimpleTable"
import { ReactComponent as IconCheck } from "assets/images/icons/icon-valid-input.svg"
import { ReactComponent as IconNotYet } from "assets/images/icons/icon-system-data.svg"
// import { ReactComponent as IconFail } from "assets/images/icons/icon-error-notification.svg"

const columns: SimpleTableProps["columns"] = [
  {
    title: "Content",
    dataIndex: "file_key",
  },
  {
    title: "Context",
    dataIndex: "is_synced",
    render: (value) => {
      if (value.is_synced) return <IconCheck className="ml-1" />
      return <IconNotYet className="ml-1" />
    },
  },
]

interface ConversationSyncTableProps {
  data?: {
    file_key: string
    is_synced: boolean
  }[]
}

const ConversationSyncTable = (props: ConversationSyncTableProps) => {
  return (
    <SimpleTable displayOD columns={columns} dataSource={props.data || []} />
  )
}
export default ConversationSyncTable
