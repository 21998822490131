import React, { useCallback, useEffect, useState } from "react"
import PageLayout from "pages/layout/PageLayout"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
  ProjectBuildDetail,
} from "pages/project-component/types"
import Header from "./organisms/ProjectComponentDetailHeader"
import {
  BuildHistoryDetail,
  dataEditorDefault,
  defaultBuildHistoryDetail,
  FileUrlProp,
  TAB_URL_PROJECT_COMPONENT,
  ViewLinkedBuildHeaders,
  ExtendedViewType,
  ComponentType,
} from "./types"
import {
  getBuildHistoriesMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectBuildListLinkedBuildMiddleware,
  getProjectComponentOtherMiddleware,
  postTabContentMiddleware,
  upload3DFile,
  delete3DFile,
  uploadSubBomFile,
  deleteSubBomFile,
} from "./services"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import InfoBuildDetail from "./organisms/InfoBuildDetail"
import HistoryTreeCard from "../../components/HistoryTree/HistoryTreeCard"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { cloneDeep, includes, debounce, remove, isEmpty } from "lodash"
import { InputEditorDefault } from "components/Input/InputEditorDefault"
import AttachmentOtherCard from "./organisms/AttachmentOtherCard"
import UpdateFileItem from "./molecules/UpdateFileItem"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { PermissionProjectComponentPage } from "./contexts/PermissionProjectComponentPage.context"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { STATUS_TYPE_FILE_BUILD } from "./build-history.constant"
import {
  checkPermissionPage,
  findHistoryTreeDefault,
  handleCheckUpdateFileComponent,
  getPrivateAssetURI,
  downloadPrivateAsset,
} from "helpers/utils"
import TableBuildCard from "pages/project-component/organisms/TableBuildCard"
import {
  emptyProjectBuildDetail,
  ProjectComponentBuildDetail,
} from "pages/project-build/project-build.type"
import { SectionCustom } from "components/Section/SectionCustom"
import { Mech3DModel } from "components/ThreeDModels/Mech3DModel"
import CustomCollapse from "./molecules/CustomCollapse"
import { SpecificationHeader } from "components/specification/SpecificationHeader"
import { useMechComponent } from "./project-component-detail.hook"
import BOMDetailCard from "./organisms/BOMDetailCard"
import { configureStore } from "stores/configureStore"
import { openModal, closeModal } from "reducers/modal"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import InputEditorDefault2 from "components/Input/InputEditorDefault2"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { startLoading } from "reducers/loading"
import { useShareInvitation } from "hooks/useShareInvitation"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"
import { getWorkerLocalStorageData } from "workers/utils"

const MechTemplate = () => {
  const { acceptedSharingProject } = useShareInvitation()

  const params = useParams<{
    idProjectComponent: string
    titlePage: string
    idProjectBuildComponent: string
  }>()
  const { workerUploadFile } = useUploadFileWorkerController()

  const locationOtherTemplate = useLocation<any>()
  const idProjectComponent = params?.idProjectComponent || ""
  const titlePage = params?.titlePage || ""
  const idProjectBuildComponent = params?.idProjectBuildComponent || ""
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const idComponentHistory = useString()
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [dataEditor, setDataEditor] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originDataEditor, setOriginDataEditor] = useState<string>("")

  const [listFileUrl, setListFileUrl] = useState<FileUrlProp[]>([])
  const updatedAtComponent = useString()
  const viewOnlyShare = useBoolean(true)
  const archiveProject = useBoolean(true)
  const archiveComponent = useBoolean(true)
  const archiveBuild = useBoolean(true)
  const isViewLinkedBuild = useBoolean(false)
  const isActiveLinedBuild = useBoolean(false)
  const [listViewLinkedBuilds, setListViewLinkedBuilds] = useState<
    ProjectBuildDetail[]
  >([])
  const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
    emptyProjectBuildDetail
  )
  const maxFile = 50
  const { projectEncryptionKeys, userId } = getWorkerLocalStorageData()

  const {
    detailViewType,
    setDetailViewType,
    mech3DFile,
    setMech3DFile,
    bomDetail,
    isUpdatingBom,
    getBOMCurrencies,
    currencies,
    getSubBomDetail,
  } = useMechComponent()

  const isReadComponent = useBoolean(true)
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail.build_status === BuildStatusNumber.DELETED_ATTACHMENT

  const componentType = "mechanical"
  // index of history
  const newMasterHistories =
    titlePage === TAB_URL_PROJECT_COMPONENT.COMPONENT
      ? histories.filter((el) => !el.project_build_component_id)
      : histories
  const indexHistory = newMasterHistories.findIndex(
    (el) => el.id === historyDetail.id
  )

  useEffect(() => {
    if (
      !idProjectComponent ||
      !includes(
        [
          TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT,
          TAB_URL_PROJECT_COMPONENT.COMPONENT,
        ],
        titlePage
      )
    ) {
      pushTo(PATH.projects)
      return
    }
    getProjectComponentDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponent,
    titlePage,
    idProjectBuildComponent,
    locationOtherTemplate.state,
  ])

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponent
      )
      if (
        !includes(
          [
            TYPE_PROJECT_COMPONENT.FIRMWARE,
            TYPE_PROJECT_COMPONENT.MECHANICAL,
            TYPE_PROJECT_COMPONENT.MISCELLANEOUS,
            TYPE_PROJECT_COMPONENT.SOFTWARE,
          ],
          dataComponentRes.type.key
        )
      ) {
        pushTo(PATH.projectComponent, {
          idProject: dataComponentRes.project_id,
        })
        return
      }
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataComponentRes.project_id
      )
      await acceptedSharingProject(dataProjectDetailRes)

      const dataProjectRole = await getProjectRoleMiddleware(
        dataComponentRes.project_id
      )
      await getHistories(dataComponentRes.id, dataComponentRes.type.key)
      await getDataBuildComponent(
        dataComponentRes.id,
        dataComponentRes.updated_at,
        dataComponentRes.is_read_comment
      )
      setComponentDetail(dataComponentRes)
      setProjectDetail(dataProjectDetailRes)
      viewOnlyShare.setValue(
        dataProjectRole.role === STATUS_PROJECT_ROLE.VIEWER
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      archiveComponent.setValue(Boolean(dataComponentRes.is_archived))
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getDataBuildComponent = async (
    oldComponentId: string,
    updatedAt: string,
    oldIsReadComment?: boolean
  ) => {
    if (titlePage === TAB_URL_PROJECT_COMPONENT.BUILD_COMPONENT) {
      await getProjectBuildComponent(idProjectBuildComponent)
      await getIsReadProjectBuildComponent(idProjectBuildComponent)
    } else {
      setProjectBuild(emptyProjectBuildDetail)
      isReadComponent.setValue(oldIsReadComment)
      const dataLinkedBuild = await getProjectBuildListLinkedBuildMiddleware(
        oldComponentId
      )
      setListViewLinkedBuilds(dataLinkedBuild)
      isActiveLinedBuild.setValue(dataLinkedBuild.length)
      archiveBuild.setValue(false)
      updatedAtComponent.setValue(updatedAt)
    }
  }

  const getHistories = async (
    oldIdComponent: string,
    oldType: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getBuildHistoriesMiddleware(
        idProjectBuildComponent && idProjectBuildComponent !== "history"
          ? idProjectBuildComponent
          : oldIdComponent,
        titlePage
      )
      setHistories(dataRes)
      if (dataRes.length) {
        await saveDataHistoryDetail(dataRes, findItemCommit, oldType)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      pushTo(PATH.projects)

      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const saveDataHistoryDetail = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit: boolean,
    oldType: string
  ) => {
    const { idHistory, newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationOtherTemplate
    )
    setHistoryDetail(newHistoryDetail)
    idComponentHistory.setValue(idHistory)
    await getDataInType(idHistory, oldType)
  }
  const getDataInType = useCallback(
    async (oldIdHistory: string, oldType: string, loading = false) => {
      try {
        const dataRes = await getProjectComponentOtherMiddleware(
          oldIdHistory,
          oldType.toLocaleLowerCase()
        )
        if (dataRes.bom_id && isEmpty(currencies)) {
          await getBOMCurrencies()
        }
        // load bom detail
        await getSubBomDetail(oldIdHistory, componentType)
        setDataEditor(dataRes.description || "")
        setOriginDataEditor(dataRes.description || "")
        setListFileUrl(dataRes.files)
        setMech3DFile({
          three_d_file: dataRes.three_d_file || "",
          three_d_file_name: dataRes.three_d_file_name || "",
          // blobURI: dataRes.three_d_file
          //   ? ((await downloadPrivateAsset(
          //       dataRes.three_d_file,
          //       {
          //       },
          //       undefined,
          //       true
          //     )) as string)
          //   : "",
          blobURI: "",
        })
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        if (loading) {
          setDataEditor(dataEditorDefault)
          setOriginDataEditor(dataEditorDefault)
          setListFileUrl([])
          isLoading.setValue(false)
        }
      }
    },
    [isLoading]
  )
  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      setDetailViewType(ExtendedViewType.HideAll)
      isLoading.setValue(true)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getDataInType(
        newHistoryDetail.id,
        componentDetail.type.key.toLocaleLowerCase(),
        true
      )
      isViewLinkedBuild.setValue(false)
    },
    [
      idComponentHistory.value,
      isLoading,
      getDataInType,
      componentDetail.type.key,
      isViewLinkedBuild,
      historyDetail,
    ]
  )

  const getProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      oldIdProjectBuildComponent
    )
    setProjectBuild(dataRes)
    archiveBuild.setValue(Boolean(dataRes.is_archived))
    updatedAtComponent.setValue(dataRes.updated_at)
  }

  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent
    )
    isReadComponent.setValue(dataRes.is_read_comment)
  }

  const updatedAtComponentDetail = (updatedAt: string) => {
    updatedAtComponent.setValue(updatedAt)
  }

  const handleUpdateDataWhenChangeHistory = () => {
    isLoading.setValue(true)
    getHistories(componentDetail.id, componentDetail.type.key, true, false)
    updatedAtComponentDetail(new Date().toISOString())
  }

  const onSubmitAdditional = async (description: string, type: string) => {
    if (
      historyDetail.status !== "Draft" ||
      !historyDetail.id ||
      !workerUploadFile
    ) {
      return
    }

    isLoading.setValue(true)
    const formData = new FormData()
    formData.append("description", description)
    await postTabContentMiddleware(
      workerUploadFile,
      historyDetail.id,
      type,
      formData,
      (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          return
        }
        handleUpdateDataTypeErrorWhenCallAPI(error)
      }
    )
  }
  const debouceOnSubmitAdditionalMessage = useCallback(
    debounce(onSubmitAdditional, 500),
    [historyDetail.id]
  )

  const handleUpdateDataWhenDeleteFile = (oldIdFile: string) => {
    const newFileURls = cloneDeep(listFileUrl)
    remove(newFileURls, (el) => el.id === oldIdFile)
    setListFileUrl(newFileURls)
    updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataWhenAddFile = async (
    fileRequest: FormData,
    fileErrors: File[]
  ) => {
    if (!idComponentHistory.value || !workerUploadFile) {
      return
    }
    configureStore.dispatch(startLoading())
    isLoading.setValue(true)
    fileErrors.forEach((fileError) => {
      toast(
        <LabelNotificationPage
          messenger={`File ${fileError.name} wrong format! Files allowed: rar,zip,7z`}
          type="warning"
        />
      )
    })
    await postTabContentMiddleware(
      workerUploadFile,
      idComponentHistory.value,
      componentDetail.type.key.toLocaleLowerCase(),
      fileRequest,
      async (type, error) => {
        isLoading.setValue(false)
        if (type === STATUS_RESPONSE.SUCCESS) {
          await handleUpdateDataTypeSuccessWhenCallAPI()
          return
        }
        handleUpdateDataTypeErrorWhenCallAPI(error)
      }
    )
  }
  const handleUpdateDataTypeSuccessWhenCallAPI = async () => {
    await getDataInType(
      idComponentHistory.value,
      componentDetail.type.key.toLocaleLowerCase(),
      true
    )
    toast(
      <LabelNotificationPage
        messenger={`Update ${componentDetail.type.key} successfully!`}
        type={STATUS_RESPONSE.SUCCESS}
      />
    )
    updatedAtComponentDetail(new Date().toISOString())
  }
  const handleUpdateDataTypeErrorWhenCallAPI = async (error: any) => {
    let message = error?.response?.data?.message
    if (error.response?.status === 413 && !message) {
      message = `The upload of file larger than ${maxFile}MB is not allowed`
    }
    toast(
      <LabelNotificationPage
        messenger={message || `Update ${componentDetail.type.key} failed!`}
        type={STATUS_RESPONSE.ERROR}
      />
    )
    isLoading.setValue(false)
  }
  const onUpdateDataEdit = (item: ProjectComponentDetail) => {
    setComponentDetail(item)
  }

  const onViewLinedBuilds = () => {
    if (!listViewLinkedBuilds.length || !isActiveLinedBuild.value) {
      return
    }
    isViewLinkedBuild.setValue(!isViewLinkedBuild.value)
  }
  const handleChangeHistoryTreeComment = (originIdHistory: string) => {
    const newHistories = cloneDeep(histories)
    const newHistoryDetail = newHistories.find(
      (el) => el.id === originIdHistory
    )
    if (newHistoryDetail) {
      handleChangeHistoryDetail(newHistoryDetail)()
    }
  }

  const uploadMech3DFile = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory.value,
        Object(newFile.get("file")).name,
        ".stl, .obj, .3ds, .stp, .step",
        "stl|stp|obj|3ds|step"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)
    configureStore.dispatch(startLoading())

    upload3DFile(
      workerUploadFile,
      idComponentHistory.value,
      componentType,
      newFile,
      (type: STATUS_RESPONSE, error: any) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.UPLOAD_3D_FILE_SUCCESS}
              type={STATUS_RESPONSE.SUCCESS}
            />
          )
          getDataInType(idComponentHistory.value, componentType).then(() => {
            setDetailViewType(ExtendedViewType.Mech3D)
          })

          return
        }
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type={STATUS_RESPONSE.ERROR}
          />
        )
      }
    )
  }

  const removeMech3DFile = () => {
    delete3DFile(
      idComponentHistory.value,
      componentType,
      mech3DFile.three_d_file_name
    )
      .then(() => {
        getDataInType(idComponentHistory.value, componentType)
          .then(() => {
            setDetailViewType(ExtendedViewType.HideAll)
            closeModal()
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_3D_FILE_SUCCESS}
                type={STATUS_RESPONSE.SUCCESS}
              />
            )
          })
          .catch((_error) => {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_3D_FILE_ERROR}
                type={STATUS_RESPONSE.ERROR}
              />
            )
          })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }
  const removeBomFile = () => {
    deleteSubBomFile(idComponentHistory.value, componentType)
      .then(() => {
        getDataInType(idComponentHistory.value, componentType)
          .then(() => {
            setDetailViewType(ExtendedViewType.HideAll)
            closeModal()
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_BOM_FILE_SUCCESS}
                type={STATUS_RESPONSE.SUCCESS}
              />
            )
          })
          .catch((_error) => {
            toast(
              <LabelNotificationPage
                messenger={MESSENGER_NOTIFICATION.DELETE_BOM_FILE_ERROR}
                type={STATUS_RESPONSE.ERROR}
              />
            )
          })
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }

  const deleteFile = (onSubmit: () => void) => {
    configureStore.dispatch(
      openModal({
        type: "Delete",
        props: {
          deleteModal: {
            title: `Are you sure to delete this file?`,
            label: `Confirmation`,
            onSubmit,
            titleButton: `Delete`,
          },
        },
      })
    )
  }

  const handleUpdateFileBom = (newFile: FormData) => {
    if (
      handleCheckUpdateFileComponent(
        idComponentHistory.value,
        Object(newFile.get("file"))?.name,
        "csv,xls,xlsx",
        "csv|xls|xlsx"
      ) ||
      !workerUploadFile
    ) {
      return
    }
    isLoading.setValue(true)

    uploadSubBomFile(
      workerUploadFile,
      idComponentHistory.value,
      componentType,
      newFile,
      (type: STATUS_RESPONSE, error: any) => {
        if (type === STATUS_RESPONSE.SUCCESS) {
          toast(
            <LabelNotificationPage
              messenger={MESSENGER_NOTIFICATION.UPLOAD_BOM_FILE_SUCCESS}
              type={STATUS_RESPONSE.SUCCESS}
            />
          )
          getDataInType(idComponentHistory.value, componentType).then(() => {
            setDetailViewType(ExtendedViewType.Bom)
          })

          return
        }
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type={STATUS_RESPONSE.ERROR}
          />
        )
      }
    )
  }

  const renderBodyViewLinkBuild = () => {
    if (isViewLinkedBuild.value) {
      return (
        <div className="flex flex-col mt-6 mr-6">
          <TableBuildCard
            listBuilds={listViewLinkedBuilds}
            customHeaders={ViewLinkedBuildHeaders}
          />
        </div>
      )
    }

    const isAction =
      checkPermissionPage({
        project: archiveProject.value,
        viewShare: viewOnlyShare.value,
        component: archiveComponent.value,
        build: archiveBuild.value,
      }) ||
      includes(
        [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
        historyDetail.status
      )
    return (
      <React.Fragment>
        <div className="grid grid-cols-box-2-min-324 md:grid-cols-4 gap-2 mr-6 mt-6 pb-2 md:pb-0 overflow-x-auto min-h-[138px]">
          <UpdateFileItem
            title={STATUS_TYPE_FILE_BUILD.MECH_3D}
            handleUpdateFile={uploadMech3DFile}
            containerFiles={`container-files-mech-3d`}
            file={mech3DFile.three_d_file}
            fileName={mech3DFile.three_d_file_name}
            onRemoveFile={() => deleteFile(removeMech3DFile)}
            isAction={isAction}
            acceptFile=".stl, .obj, .3ds, .stp, .step"
            detailViewType={detailViewType}
            setDetailViewType={setDetailViewType}
            isDeleted={isDeleted}
            versionId={historyDetail.id}
          />
          <UpdateFileItem
            title={STATUS_TYPE_FILE_BUILD.BOM}
            handleUpdateFile={handleUpdateFileBom}
            containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
            file={bomDetail?.file || ""}
            fileName={bomDetail?.file_name || ""}
            onRemoveFile={() => deleteFile(removeBomFile)}
            isAction={isAction}
            acceptFile=".csv,.xls,.xlsx"
            detailViewType={detailViewType}
            setDetailViewType={setDetailViewType}
            isDeleted={isDeleted}
            versionId={historyDetail.id}
          />
        </div>
        <AttachmentOtherCard
          listFileUrl={listFileUrl}
          viewOnly={isAction}
          typeOther={componentDetail.type.key.toLocaleLowerCase()}
          handleUpdateDataWhenDeleteFile={handleUpdateDataWhenDeleteFile}
          handleUpdateDataWhenAddFile={handleUpdateDataWhenAddFile}
          isDeleted={isDeleted}
          versionId={historyDetail.id}
        />
        <div className="flex flex-col mr-6">
          {detailViewType === ExtendedViewType.Mech3D ? (
            <div className="mt-6">
              <CustomCollapse
                changeHeaderBorder={false}
                isToggle={true}
                buttonClass="border-0"
                header={<SpecificationHeader title="3D file viewer" />}
                childrenBody={
                  <div className="py-3 mr-6">
                    {mech3DFile.three_d_file ? (
                      <Mech3DModel
                        url={mech3DFile.three_d_file}
                        fileName={mech3DFile.three_d_file}
                      />
                    ) : null}
                  </div>
                }
              />
            </div>
          ) : null}
        </div>
        <div
          className={`flex flex-col mt-6 mr-6 ${
            detailViewType !== ExtendedViewType.Bom ? "hidden" : ""
          }`}
        >
          <BOMDetailCard
            BOMJson={bomDetail?.bom_json || []}
            currencies={currencies}
            idHistory={historyDetail.id}
            updatedAtBOM={bomDetail?.updated_at}
            onUpdateWhenAddSupplier={() =>
              getSubBomDetail(historyDetail.id, componentType)
            }
            bomFileName={bomDetail?.file_name || ""}
            nameBOM={bomDetail?.file_name || "N/A"}
            mouserData={bomDetail?.mouser_data || []}
            additionalJson={bomDetail?.additional_json || []}
            inviteeBoms={[]}
            isActiveBtnSync={
              (indexHistory === 0 &&
                !historyDetail.project_build_component_id) || // first history => active
              historyDetail.status === STATUS_BUILD.DRAFT || // draft => always active
              // if index of history is second and the first is draft => active
              (indexHistory === 1 &&
                histories[0]?.status === STATUS_BUILD.DRAFT)
            }
            componentType={ComponentType.Mechanical}
            isAddColumn={isUpdatingBom}
          />
        </div>

        <div
          className="flex flex-col mr-6 mt-6 p-3"
          style={{
            border: "1px solid #E4E4E4",
            borderRadius: 4,
            background: "#FFFFFF",
          }}
        >
          <p
            className="font-semibold mb-2"
            style={{
              fontSize: 14,
              lineHeight: "24px",
              color: "#111111",
            }}
          >
            Additional info
          </p>
          <InputEditorDefault2
            readOnly={
              checkPermissionPage({
                project: archiveProject.value,
                viewShare: viewOnlyShare.value,
                component: archiveComponent.value,
                build: archiveBuild.value,
              }) ||
              includes(
                [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
                historyDetail.status
              )
            }
            setValue={(description) => {
              if (description !== dataEditor) {
                debouceOnSubmitAdditionalMessage(
                  description,
                  componentDetail.type.key.toLocaleLowerCase()
                )
              }
            }}
            value={dataEditor}
            versionId={historyDetail.id}
          />
        </div>
      </React.Fragment>
    )
  }
  const renderLayout = () => {
    if (!projectDetail.id) {
      return null
    }
    if (!projectEncryptionKeys[projectDetail.id]) {
      return (
        <EncryptedDataWarning
          type="project"
          isOwner={projectDetail.created_by === userId}
        />
      )
    }
    return (
      <PermissionProjectComponentPage.Provider
        value={{
          archiveProject: archiveProject.value,
          viewOnlyShare: viewOnlyShare.value,
          archiveComponent: archiveComponent.value,
          archiveBuild: archiveBuild.value,
          isListActionHistory:
            !viewOnlyShare.value && listFileUrl.length ? true : false,
          isNoUpdateFilePCB: includes(
            [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED],
            historyDetail.status
          ),
          titlePage,
          idProjectBuildComponent,
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <InfoBuildDetail
            projectComponentDetail={componentDetail}
            projectBuild={projectBuild}
            historyDetail={historyDetail}
            handleUpdateDataWhenChangeHistory={
              handleUpdateDataWhenChangeHistory
            }
            histories={histories}
            onUpdateDataEdit={onUpdateDataEdit}
            isViewLinkedBuild={isViewLinkedBuild.value}
            isReadComponent={isReadComponent.value}
            isNotDownload={listFileUrl.length ? false : true}
            setIsReadComponent={isReadComponent.setValue}
            onViewLinedBuilds={onViewLinedBuilds}
            isActiveLinedBuild={isActiveLinedBuild.value}
            handleChangeHistoryTreeComment={handleChangeHistoryTreeComment}
            isDeleted={isDeleted}
            commitButtonStage={{
              isActive:
                (listFileUrl.length > 0 || mech3DFile.three_d_file) &&
                historyDetail.status === STATUS_BUILD.DRAFT
                  ? true
                  : false,
              tooltipHelper: `Please upload ${componentDetail.type.key.toLocaleLowerCase()} attachment or 3D file`,
            }}
            setArchiveComponent={archiveComponent.setValue}
          />

          <SectionCustom>
            <HistoryTreeCard
              histories={histories}
              historyDetail={historyDetail}
              handleChangeHistoryDetail={handleChangeHistoryDetail}
              typeComponent={componentDetail.type.key}
              titlePage={titlePage}
            />
            {renderBodyViewLinkBuild()}
          </SectionCustom>
        </div>
      </PermissionProjectComponentPage.Provider>
    )
  }
  return (
    <PageLayout
      childrenHeader={
        <Header
          projectDetail={projectDetail}
          projectComponentDetail={componentDetail}
          updatedAtComponent={updatedAtComponent.value}
          titlePage={titlePage}
          projectBuild={projectBuild}
          idProjectBuildComponent={idProjectBuildComponent}
        />
      }
      heightHeader={48}
    >
      {renderLayout()}
    </PageLayout>
  )
}
export default MechTemplate
