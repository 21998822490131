import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import PageLayout from "pages/layout/PageLayout"
import Header from "./organisms/ProjectHeader"
import NoData from "./molecules/NoData"
import {
  ProjectDetail,
  defaultProjectDetail,
  defaultCpnTypeSelected,
  STATUS_PROJECT_ROLE,
  defaultStatusSelected,
  ProjectStatus,
} from "pages/projects/types"
import {
  archiveProjectMiddleware,
  deleteProjectAttachment,
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
  putUpdateStatusMiddleware,
} from "pages/projects/services/api"
import { useBoolean, useWindowSize } from "helpers/hooks"
import {
  ProjectComponentDetail,
  GetUsersInProjectProp,
  ProjectBuildDetail,
  Folder,
} from "./types"
import {
  deleteFolderMiddleWare,
  getBuildStatusMiddleware,
  getProjectBuildsMiddleware,
  getProjectComponentsMiddleware,
  moveComponentInOrOutOfAFolder,
} from "./services/api"
import { STATUS_RESPONSE } from "types"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { cloneDeep, isUndefined, remove } from "lodash"
import { getComponentTypesMiddleware } from "pages/project-component-detail/services"
import { SelectedDefaultProp } from "components/Select/types"
import InfoProjectCard from "./organisms/InfoProjectCard"
import TabProjectComponent from "./organisms/TabProjectComponent"
import FormNewComponent from "./organisms/FormNewComponent"
import ComponentDetailCard from "./organisms/ComponentDetailCard"
import FormProject from "pages/projects/organisms/FormProject"
import ModalDelete from "components/ModalCustom/ModalDelete"
import FormShare from "pages/projects/organisms/FormShare"
import { TAB_PROJECT_COMPONENT } from "./project-component.constant"
import TableBuildCard from "./organisms/TableBuildCard"
import { PermissionProjectPage } from "./contexts/PermissionProjectPage.context"
import {
  checkPermissionPage,
  umamiTracking,
  customLocalStorageHandler,
} from "helpers/utils"
import { useAppSelector } from "hooks/useApp"
import { STATUS_BUILD } from "components/Status/types"
import { EVENT } from "constants/events"
import FolderCard from "./organisms/FolderCard"
import FormNewFolder from "./organisms/FormNewFolder"
import IconArrowRight from "assets/images/icons/icon-arrow-right-breadcrumb.svg"
import moment from "moment"
import { NAME_LOCALSTORAGE } from "constants/localStorage"
import { useShareInvitation } from "hooks/useShareInvitation"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"
import { getWorkerLocalStorageData } from "workers/utils"

interface LocationParams {
  pathname: string
  state: any
  search: string
  hash: string
  key: string
}

const ProjectComponent = () => {
  const params = useParams<{
    idProject: string
    idFolder: string
  }>()
  const routerState = useLocation() as LocationParams
  const userInfo = useAppSelector((state) => state.userInfo)
  const idProjectParams = params?.idProject || ""
  const idFolder = params?.idFolder || ""

  const { storageData, handleSetLocalStorage } = customLocalStorageHandler(
    NAME_LOCALSTORAGE.PROJECT_COMPONENT
  )
  const storageDataProjectComponent = !isUndefined(storageData)
    ? storageData
    : []
  const cookieDetail = storageDataProjectComponent.find(
    (el) => el.id === idProjectParams
  )
  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isLoading = useBoolean()
  const [listComponents, setListComponent] = useState<ProjectComponentDetail[]>(
    []
  )
  const [listFolders, setListFolder] = useState<Folder[]>([])
  const [cpnTypeOptions, setCpnTypeOptions] = useState<SelectedDefaultProp[]>([
    defaultCpnTypeSelected,
  ])
  const [cpnTypeSelected, setCpnTypeSelected] = useState<SelectedDefaultProp>(
    cookieDetail ? cookieDetail.type : defaultCpnTypeSelected
  )

  /// componenent filter
  const showArchive = useBoolean(cookieDetail ? cookieDetail.isArchived : true)
  const showDeletedAttachment = useBoolean(
    cookieDetail ? cookieDetail.isDeleted : false
  )

  const isNewComponent = useBoolean(false)
  const isNewFolder = useBoolean(false)
  const [summary, setSummary] = useState({
    components: 0,
    builds: 0,
    folders: 0,
  })
  const isOpenFormDialog = useBoolean(false)
  const isArchive = useBoolean(false)
  const isDeleteProject = useBoolean(false)
  const isShare = useBoolean(false)
  const [tabProjectComponent, setTabProjectComponent] = useState(
    cookieDetail ? cookieDetail.tabComponent : TAB_PROJECT_COMPONENT.COMPONENT
  )
  const [buildStatusOptions, setBuildStatusOptions] = useState<
    SelectedDefaultProp[]
  >([defaultStatusSelected])
  const [buildStatusSelected, setBuildStatusSelected] =
    useState<SelectedDefaultProp>(
      cookieDetail ? cookieDetail.status : defaultStatusSelected
    )
  const [listBuilds, setListBuilds] = useState<ProjectBuildDetail[]>([])
  const viewOnlyShare = useBoolean(true)
  const archiveProject = useBoolean(true)
  const isDeleted = projectDetail.status === ProjectStatus.DeletedAttachment
  const { isMobile, width } = useWindowSize()
  const { acceptedSharingProject } = useShareInvitation()
  const { projectEncryptionKeys } = getWorkerLocalStorageData()

  useEffect(() => {
    if (!idProjectParams) {
      pushTo(PATH.projects)
      return
    }
    getProjectDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProjectParams])

  useEffect(() => {
    if (!idFolder) {
      setTabProjectComponent(TAB_PROJECT_COMPONENT.COMPONENT)
    } else setTabProjectComponent(TAB_PROJECT_COMPONENT.FOLDER)
  }, [idFolder])

  const getProjectDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        idProjectParams
      )

      await acceptedSharingProject(dataProjectDetailRes)

      const dataCpnTypeRes = await getComponentTypesMiddleware()
      const dataProjectRoleRes = await getProjectRoleMiddleware(idProjectParams)
      const dataBuildStatudRes = await getBuildStatusMiddleware()
      setBuildStatusOptions([
        ...[defaultStatusSelected],
        ...dataBuildStatudRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])

      setProjectDetail(dataProjectDetailRes)
      setCpnTypeOptions([
        ...[defaultCpnTypeSelected],
        ...dataCpnTypeRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])
      // setSummary(dataSumary)
      viewOnlyShare.setValue(
        dataProjectRoleRes.role === STATUS_PROJECT_ROLE.VIEWER
      )
      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))

      await changeDataTab(
        tabProjectComponent,
        showArchive.value,
        showDeletedAttachment.value,
        cpnTypeSelected,
        buildStatusSelected,
        true
      )
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }
  const getProjectComponents = async (
    isArchived: boolean,
    isDeletedAttachment: boolean,
    type?: number
  ) => {
    try {
      const query = {
        type,
        is_archived: isArchived ? 1 : 0,
        is_deleted: isDeletedAttachment ? 1 : 0,
      }
      if (!type || type < 0) {
        delete query.type
      }

      const dataRes = await getProjectComponentsMiddleware(
        idProjectParams,
        query
      )
      if (
        idFolder &&
        !dataRes.groups.find((folder) => folder.id === idFolder)
      ) {
        pushTo(PATH.projectComponent, { idProject: idProjectParams })
      }
      setListComponent(dataRes.components)
      setListFolder(dataRes.groups)
      setSummary((prev) => {
        return {
          ...prev,
          components:
            dataRes.components.length +
            dataRes.groups.reduce((a, b) => a + b.components.length, 0),
        }
      })
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
    }
  }

  const getProjectBuilds = async (
    isArchived: boolean,
    isDeletedAttachment: boolean,
    type?: number,
    status?: number
  ) => {
    try {
      const query = {
        type,
        is_archived: isArchived ? 1 : 0,
        is_deleted: isDeletedAttachment ? 1 : 0,
        status,
      }
      if (!type || type < 0) {
        delete query.type
      }
      if (!status || status < 0) {
        delete query.status
      }

      const dataRes = await getProjectBuildsMiddleware(idProjectParams, query)
      setListBuilds(dataRes)
      setSummary((prev) => {
        return {
          ...prev,
          builds: dataRes.length || 0,
        }
      })
      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
    }
  }
  const onUpdateDataEditFolder = (name: string, oldIndex: number) => {
    const newData = cloneDeep(listFolders)
    newData[oldIndex].name = name
    setListFolder(newData)
  }
  const onUpdateDataEditComponent = (
    item: ProjectComponentDetail,
    oldIndex: number,
    folder?: Folder
  ) => {
    if (folder) {
      folder.components[oldIndex].name = item.name
      folder.components[oldIndex].updated_at = item.updated_at
      setListFolder([...listFolders])
    } else {
      const newData = cloneDeep(listComponents)
      newData[oldIndex].name = item.name
      newData[oldIndex].updated_at = item.updated_at
      setListComponent(newData)
    }

    updateAtProjectDetail(item.updated_at)
  }

  const onUpdateDataDuplicate = (
    item: ProjectComponentDetail,
    folder?: Folder
  ) => {
    if (folder) {
      folder.components.unshift(item)
      setListFolder([...listFolders])
    } else {
      const newData = cloneDeep(listComponents)
      newData.unshift(item)
      setListComponent(newData)
    }
    const newSummary = cloneDeep(summary)
    newSummary.components = newSummary.components + 1
    setSummary(newSummary)
    updateLiveComponentCountAndUpdatedTime(
      projectDetail.live_component_count + 1,
      item.created_at
    )
  }

  const onDeleteComponent = (item: ProjectComponentDetail) => {
    let newData = cloneDeep(listComponents)
    newData = newData.filter((data) => data.id !== item.id)
    setListComponent(newData)
    const newSummary = cloneDeep(summary)
    newSummary.components = newSummary.components - 1
    setSummary(newSummary)
    updateLiveComponentCountAndUpdatedTime(
      projectDetail.live_component_count - 1
    )
  }
  const onDeleteComponentAttachments = (
    component: ProjectComponentDetail,
    folder?: Folder
  ) => {
    if (folder) {
      const newFolders = listFolders.map((item) => {
        if (item.id === folder.id) {
          item.components = item.components.filter(
            (subComponent) => subComponent.id !== component.id
          )
        }
        return item
      })
      setListFolder(newFolders)
    } else {
      let newData = cloneDeep(listComponents)
      newData = newData.filter((data) => data.id !== component.id)
      setListComponent(newData)
    }
    const newSummary = cloneDeep(summary)
    newSummary.components = newSummary.components - 1
    setSummary(newSummary)

    updateLiveComponentCountAndUpdatedTime(
      projectDetail.live_component_count - 1
    )
  }

  const onUpdateDataArcvhive = async (item: ProjectComponentDetail) => {
    await getProjectComponents(
      showArchive.value,
      showDeletedAttachment.value,
      Number(cpnTypeSelected.value)
    )
    updateAtProjectDetail(item.updated_at)
  }
  const updateAtProjectDetail = (updatedAt: string) => {
    setProjectDetail({
      ...projectDetail,
      updated_at: updatedAt,
    })
  }

  const updateLiveComponentCountAndUpdatedTime = (
    liveComponentCount: number,
    updatedAt?: string
  ) => {
    setProjectDetail({
      ...projectDetail,
      updated_at: updatedAt ? updatedAt : projectDetail.updated_at,
      live_component_count: liveComponentCount,
    })
  }
  const handleOnSelectCpnType = (event: SelectedDefaultProp) => {
    setCpnTypeSelected(event)
  }

  const handleOnSelectBuildStatus = (event: SelectedDefaultProp) => {
    if (event.value !== buildStatusSelected.value) {
      setBuildStatusSelected(event)
    }
  }

  const onClickNewComponent = () => {
    if (
      checkPermissionPage({
        project: archiveProject.value,
        notificationProject: true,
        viewShare: viewOnlyShare.value,
        notificationViewShare: true,
      })
    ) {
      return
    }
    isNewComponent.setValue(true)
  }

  const onCloseModalFormNewComponent = () => {
    isNewComponent.setValue(false)
  }
  const onCloseModalFormNewFolder = () => {
    isNewFolder.setValue(false)
  }
  const onClickNewFolder = () => {
    if (
      checkPermissionPage({
        project: archiveProject.value,
        notificationProject: true,
        viewShare: viewOnlyShare.value,
        notificationViewShare: true,
      })
    ) {
      return
    }
    isNewFolder.setValue(true)
  }

  const onUpdateComponent = (
    newComponent: ProjectComponentDetail,
    folderId?: string
  ) => {
    if (folderId) {
      const newListFolders = listFolders.map((folder) => {
        if (folder.id === folderId) {
          folder.components.unshift({
            ...newComponent,
            project_component_history: {
              ...newComponent.project_component_history,
              code: "",
            },
            last_version_status: STATUS_BUILD.DRAFT,
          })
        }
        return folder
      })
      setListFolder(newListFolders)
    } else {
      const newListComponents = cloneDeep(listComponents)
      newListComponents.unshift({
        ...newComponent,
        project_component_history: {
          ...newComponent.project_component_history,
          code: "",
        },
        last_version_status: STATUS_BUILD.DRAFT,
      })
      setListComponent(newListComponents)
    }
    //
    const newSummary = cloneDeep(summary)
    newSummary.components = newSummary.components + 1
    setSummary(newSummary)
    isNewComponent.setValue(false)
    updateLiveComponentCountAndUpdatedTime(
      projectDetail.live_component_count + 1,
      newComponent.updated_at
    )
  }

  const onUpdateFolder = (newFolder: Folder) => {
    setListFolder([newFolder, ...listFolders])
    setSummary({ ...summary, folders: summary.folders + 1 })
    isNewFolder.setValue(false)
  }

  const handleChangeStatusProject =
    (idStatus: string, callback: () => void) => () => {
      if (!idProjectParams) {
        return
      }
      isLoading.setValue(true)
      putUpdateStatusMiddleware(
        idProjectParams,
        {
          status: Number(idStatus),
        },
        (type: STATUS_RESPONSE, messenger: string, data?: ProjectDetail) => {
          isLoading.setValue(false)
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          if (type === STATUS_RESPONSE.SUCCESS && data) {
            setProjectDetail(data)
            callback()
          }
        }
      )
    }

  const onClickAction =
    (key: "edit" | "archive" | "delete", callback: () => void) => () => {
      switch (key) {
        case "edit":
          isOpenFormDialog.setValue(true)
          break
        case "archive":
          isArchive.setValue(true)
          break
        case "delete":
          isDeleteProject.setValue(true)
          break
      }
      callback()
    }

  const handleChangeData = (newProject?: ProjectDetail) => {
    if (!newProject) {
      return
    }
    setProjectDetail(newProject)
    archiveProject.setValue(Boolean(newProject.is_archived))
  }

  const onArchiveProject = () => {
    if (!projectDetail?.id) {
      return
    }
    isLoading.setValue(true)
    archiveProjectMiddleware(
      projectDetail.id,
      projectDetail.is_archived,
      (type: STATUS_RESPONSE, messenger: string, data?: ProjectDetail) => {
        if (type === STATUS_RESPONSE.SUCCESS && data) {
          setProjectDetail(data)
          isArchive.setValue(false)
          archiveProject.setValue(Boolean(data.is_archived))
        }
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        isLoading.setValue(false)
      },
      projectDetail.name
    )
  }
  const onDeleteProject = () => {
    if (!projectDetail?.id) {
      return
    }
    isLoading.setValue(true)
    deleteProjectAttachment(projectDetail.id)
      .then(() => {
        toast(
          <LabelNotificationPage
            messenger={"Delete project successfully!"}
            type="success"
          />
        )
        pushTo(PATH.projects)
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={
              error.response?.data?.message || "Delete project failed!"
            }
            type="error"
          />
        )
      })
  }

  const onCloseFormShare = (newUserRole: GetUsersInProjectProp[]) => () => {
    isShare.setValue(false)
    const newProjectDetail = cloneDeep(projectDetail)
    newProjectDetail.role_users = newUserRole
    setProjectDetail(newProjectDetail)
  }

  const handleShareModal = () => {
    if (
      checkPermissionPage({
        project: archiveProject.value,
        notificationProject: true,
        viewShare: userInfo.id !== projectDetail.created_by,
        notificationViewShare: true,
      })
    ) {
      return
    }
    isShare.setValue(true)
  }

  const onChangeTab = (newTab: TAB_PROJECT_COMPONENT) => () => {
    if (newTab === tabProjectComponent) {
      return
    }
    setTabProjectComponent(newTab)
    changeDataTab(
      newTab,
      showArchive.value,
      showDeletedAttachment.value,
      cpnTypeSelected,
      buildStatusSelected
    )
  }

  const onChangeTabToFolder = () => {
    if (tabProjectComponent === TAB_PROJECT_COMPONENT.FOLDER) return
    setTabProjectComponent(TAB_PROJECT_COMPONENT.FOLDER)
  }
  const onDeleteFolder = (deletedFolder: Folder) => {
    deleteFolderMiddleWare(deletedFolder.id, deletedFolder.name)
      .then((_res) => {
        setListFolder(
          listFolders.filter((folder) => folder.id !== deletedFolder.id)
        )
        setSummary({
          ...summary,
          folders: summary.folders - 1,
          components: summary.components - deletedFolder.components.length,
        })
        setProjectDetail({
          ...projectDetail,
          live_component_count:
            projectDetail.live_component_count -
            deletedFolder.components.length,
        })
        toast(
          <LabelNotificationPage
            messenger={"Delete folder successfully."}
            type="success"
          />
        )
      })
      .catch((_error) => {
        toast(
          <LabelNotificationPage
            messenger={"Delete folder failed! Please contact to administrator."}
            type="error"
          />
        )
      })
  }
  const changeDataTab = async (
    newTab: TAB_PROJECT_COMPONENT,
    newIsArchived: boolean,
    newIsDeleted: boolean,
    type: SelectedDefaultProp,
    status: SelectedDefaultProp,
    firstLoad: boolean = false
  ) => {
    isLoading.setValue(true)

    if (
      newTab === TAB_PROJECT_COMPONENT.COMPONENT ||
      newTab === TAB_PROJECT_COMPONENT.FOLDER ||
      firstLoad
    ) {
      setListComponent([])
      hanleChangeCookiesComponent(
        newIsArchived,
        newIsDeleted,
        newTab,
        type,
        status
      )
      await getProjectComponents(
        newIsArchived,
        newIsDeleted,
        Number(type.value)
      )
      if (!firstLoad) {
        return
      }
    }
    if (!firstLoad) {
      setListBuilds([])

      hanleChangeCookiesComponent(
        newIsArchived,
        newIsDeleted,
        newTab,
        type,
        status
      )
    }
    await getProjectBuilds(
      newIsArchived,
      newIsDeleted,
      Number(type.value),
      Number(status.value)
    )
  }

  const hanleChangeCookiesComponent = (
    newIsArchived: boolean,
    newIsDeleted: boolean,
    newTab: TAB_PROJECT_COMPONENT,
    newType: SelectedDefaultProp,
    newStatus?: SelectedDefaultProp
  ) => {
    const newCookies = cloneDeep(storageDataProjectComponent)
    const index = newCookies.findIndex((el) => el.id === idProjectParams)
    const dataSave = {
      id: idProjectParams,
      isArchived: newIsArchived,
      isDeleted: newIsDeleted,
      tabComponent: newTab,
      type: newType,
      status: newStatus,
    }
    if (index > -1) {
      newCookies[index] = dataSave
    } else {
      newCookies.push(dataSave)
    }
    handleSetLocalStorage(newCookies)
  }

  const handleUpdateDataWhenChangeTableBuild = (
    newData: ProjectBuildDetail,
    key: "duplicate" | "archive" | "delete" | "delete_attachment"
  ) => {
    const newListBulds = cloneDeep(listBuilds)
    updateAtProjectDetail(new Date().toISOString())
    if (key === "duplicate") {
      const newSummary = cloneDeep(summary)
      newSummary.builds = newSummary.builds + 1
      setSummary(newSummary)
      newListBulds.unshift(newData)
      setListBuilds(newListBulds)
      return
    }

    const indexBuild = newListBulds.findIndex((el) => el.id === newData.id)
    if (indexBuild < 0) {
      return
    }
    if (key === "delete_attachment") {
      const newBuilds = cloneDeep(listBuilds)
      const indexChanged = newBuilds.findIndex((i) => i.id === newData.id)
      if (indexChanged > -1) {
        newBuilds[indexChanged] = {
          ...newBuilds[indexChanged],
          status: STATUS_BUILD.DELETED,
        }
      }
      setListBuilds(newBuilds)
      return
    }
    if (key === "delete") {
      const newBuilds = cloneDeep(listBuilds)
      setListBuilds(newBuilds.filter((item) => item.id !== newData.id))
      const newSummary = cloneDeep(summary)
      newSummary.builds = newSummary.builds - 1
      setSummary(newSummary)
      return
    }
    if (showArchive.value) {
      newListBulds[indexBuild] = newData
    } else {
      remove(newListBulds, (_el, index) => indexBuild === index)
    }
    setListBuilds(newListBulds)
  }

  const onMoveComponentInOrOutAFolder = (
    folder: Folder,
    component: ProjectComponentDetail,
    type: "in" | "out"
  ) => {
    moveComponentInOrOutOfAFolder(
      folder.id,
      component.id,
      type,
      component.code,
      folder.name
    )
      .then((_res) => {
        const newFolderList = listFolders.map((subFolder) => {
          if (subFolder.id === folder.id) {
            if (type === "in") {
              subFolder.components.unshift(component)
            } else {
              subFolder.components = subFolder.components.filter(
                (item) => item.id !== component.id
              )
            }
          }
          return subFolder
        })
        let newListComponent: ProjectComponentDetail[] = []
        if (type === "in") {
          newListComponent = listComponents.filter(
            (item) => item.id !== component.id
          )
        } else {
          listComponents.push(component)
          listComponents.sort((a, b) =>
            moment(a.created_at) > moment(b.created_at) ? 1 : -1
          )
          newListComponent = listComponents
        }
        setListFolder(newFolderList)
        setListComponent([...newListComponent])
        toast(
          <LabelNotificationPage
            messenger={
              type === "in"
                ? `Move component ${component.name} to folder ${folder.name} successfully.`
                : `Move component ${component.name} to root project folder successfully.`
            }
            type="success"
          />
        )
      })
      .catch((error) => {
        toast(
          <LabelNotificationPage
            messenger={error.response?.data?.message}
            type="error"
          />
        )
      })
  }

  const renderBody = () => {
    if (tabProjectComponent === TAB_PROJECT_COMPONENT.FOLDER) {
      const activeFolder = listFolders.find((item) => item.id === idFolder)
      if (!activeFolder) {
        return
      }
      return (
        <div className="h-full">
          <div className="flex absolute top-8 ">
            <p
              title={`Components`}
              className="hover-breadcrumbs-text cursor-pointer"
              onClick={() => {
                pushTo(PATH.projectComponent, {
                  idProject: activeFolder.project_id,
                })
              }}
            >
              {" "}
              Components{" "}
            </p>
            <img src={IconArrowRight} alt="icon" />
            <p
              className="text-sm font-semibold text-black ml-[6px] overflow-hidden text-ellipsis whitespace-nowrap w-full"
              style={{ maxWidth: `${isMobile ? width - 200 : width - 450}px` }}
            >
              {activeFolder.name}
            </p>
          </div>
          {!activeFolder.components.length ? (
            <div className="w-full h-full ">
              <NoData
                onClickNewComponent={onClickNewComponent}
                textContent={"You have not created any component!"}
                tab={TAB_PROJECT_COMPONENT.FOLDER}
                idProject={projectDetail.id}
                isShowButton={true}
              />
            </div>
          ) : (
            <div>
              <div className="grid grid-cols-auto-fill-min-310 gap-6 my-1 mr-6">
                {activeFolder.components.map((el, index) => {
                  return (
                    <ComponentDetailCard
                      key={index}
                      item={el}
                      indexCard={index}
                      activeFolder={activeFolder}
                      onUpdateDataEdit={onUpdateDataEditComponent}
                      onUpdateDataDuplicate={onUpdateDataDuplicate}
                      onUpdateDataArcvhive={onUpdateDataArcvhive}
                      onDeleteComponent={onDeleteComponent}
                      onDeleteComponentAttachments={
                        onDeleteComponentAttachments
                      }
                      onMoveComponentInOrOutAFolder={
                        onMoveComponentInOrOutAFolder
                      }
                      isOwner={projectDetail.created_by === userInfo.id}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )
    }
    if (isLoading.value && (!listComponents.length || !listBuilds.length)) {
      return null
    }

    if (tabProjectComponent === TAB_PROJECT_COMPONENT.BUILD) {
      if (!listBuilds.length) {
        if (isDeleted) {
          return null
        }
        return (
          <div className="w-full h-full">
            <NoData
              onClickNewComponent={onClickNewComponent}
              textContent={
                !Number(cpnTypeSelected.value) &&
                // isUndefined(buildStatusSelected.value)
                !summary.builds
                  ? "You have not created any Build!"
                  : "No builds found!"
              }
              tab={TAB_PROJECT_COMPONENT.BUILD}
              idProject={projectDetail.id}
              isShowButton={!Number(cpnTypeSelected.value) && !summary.builds}
            />
          </div>
        )
      }
      return (
        <div className="w-full pr-6">
          <TableBuildCard
            listBuilds={listBuilds}
            handleUpdateDataWhenChangeTableBuild={
              handleUpdateDataWhenChangeTableBuild
            }
            customStyleFieldStatus={{
              justifyContent: "start",
            }}
            canDeleteAttachments={userInfo.id === projectDetail.created_by}
          />
        </div>
      )
    }
    if (!listComponents.length && isDeleted) {
      return null
    }
    if (!listComponents.length && !listFolders.length) {
      return (
        <div className="w-full h-full">
          <NoData
            onClickNewComponent={onClickNewComponent}
            textContent={"You have not created any component or folder!"}
            tab={TAB_PROJECT_COMPONENT.COMPONENT}
            idProject={projectDetail.id}
            isShowButton={
              !listComponents.length &&
              !listComponents.length &&
              !projectDetail.is_archived
            }
            onClickNewFolder={onClickNewFolder}
          />
        </div>
      )
    }

    return (
      <div className="w-full">
        {listFolders.length !== 0 && (
          <div>
            <p className="text-sm font-body font-semibold leading-6 mb-4">
              Folders
            </p>
            <div className="grid grid-cols-auto-fill-min-310 gap-6 mt-1 mb-9 mr-6">
              {listFolders.map((folder, index) => {
                return (
                  <FolderCard
                    key={folder.id}
                    item={folder}
                    indexCard={index}
                    onChangeTabToFolder={onChangeTabToFolder}
                    onUpdateDataEdit={onUpdateDataEditFolder}
                    onDeleteFolder={() => onDeleteFolder(folder)}
                    isOwner={projectDetail.created_by === userInfo.id}
                  />
                )
              })}
            </div>
          </div>
        )}
        {listComponents.length !== 0 && (
          <div>
            <p className="text-sm font-body font-semibold leading-6 mb-4">
              Components
            </p>
            <div className="grid grid-cols-auto-fill-min-310 gap-6 my-1 mr-6">
              {listComponents.map((el, index) => {
                return (
                  <ComponentDetailCard
                    key={index}
                    item={el}
                    indexCard={index}
                    folders={listFolders}
                    onUpdateDataEdit={onUpdateDataEditComponent}
                    onUpdateDataDuplicate={onUpdateDataDuplicate}
                    onUpdateDataArcvhive={onUpdateDataArcvhive}
                    onDeleteComponent={onDeleteComponent}
                    onMoveComponentInOrOutAFolder={
                      onMoveComponentInOrOutAFolder
                    }
                    onDeleteComponentAttachments={onDeleteComponentAttachments}
                    isOwner={projectDetail.created_by === userInfo.id}
                    isNewFolder={isNewFolder}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }

  const renderLayout = () => {
    if (!projectDetail.id) {
      return null
    }
    if (!projectEncryptionKeys[projectDetail.id]) {
      return (
        <EncryptedDataWarning
          type="project"
          isOwner={projectDetail.created_by === userInfo.id}
        />
      )
    }

    return (
      <PermissionProjectPage.Provider
        value={{
          archiveProject: archiveProject.value,
          viewOnlyShare: viewOnlyShare.value,
        }}
      >
        <div className="flex flex-col h-full">
          <InfoProjectCard
            projectDetail={projectDetail}
            handleChangeStatusProject={handleChangeStatusProject}
            onClickAction={onClickAction}
            handleShare={handleShareModal}
            handleChangeData={handleChangeData}
            isDeleted={isDeleted}
          />
          <div className="flex flex-col grow justify-between md:overflow-hidden relative">
            <TabProjectComponent
              tab={tabProjectComponent}
              onChangeTab={onChangeTab}
              showArchive={showArchive}
              showDeletedAttachment={showDeletedAttachment}
              onClickNewComponent={onClickNewComponent}
              onClickNewFodler={onClickNewFolder}
              cpnTypeOptions={cpnTypeOptions}
              setCpnTypeOptions={setCpnTypeOptions}
              buildStatusOptions={buildStatusOptions}
              cpnTypeSelected={cpnTypeSelected}
              setCpnTypeSelected={setCpnTypeSelected}
              buildStatusSelected={buildStatusSelected}
              setBuildStatusSelected={setBuildStatusSelected}
              handleOnSelectCpnType={handleOnSelectCpnType}
              handleOnSelectBuildStatus={handleOnSelectBuildStatus}
              summary={summary}
              idProject={projectDetail.id}
              isDeleted={isDeleted}
              onApplyFilter={() => {
                changeDataTab(
                  tabProjectComponent,
                  showArchive.value,
                  showDeletedAttachment.value,
                  cpnTypeSelected,
                  buildStatusSelected
                )
                if (tabProjectComponent === TAB_PROJECT_COMPONENT)
                  umamiTracking(EVENT.COMPONENT.FILTER)
                else umamiTracking(EVENT.BUILD.FILTER)
              }}
              folderName={routerState.state?.folderName}
            />
            {tabProjectComponent === TAB_PROJECT_COMPONENT.COMPONENT ? (
              <div className="px-6 text-sm text-black font-normal">
                <p>
                  Component used: {projectDetail.live_component_count || 0}/
                  {projectDetail.component_per_project_limit || 0} component
                  {projectDetail.component_per_project_limit > 0 ? "s" : ""}
                </p>
              </div>
            ) : null}
            <div className="flex h-full pl-6 pt-6 pb-5 md:overflow-hidden">
              <div className="w-full overflow-auto">{renderBody()}</div>
            </div>
          </div>
        </div>
        <p className="absolute text-[#7A7A7A] font-medium text-[12px] leading-[18px] text-time bottom-0 right-0">
          {projectDetail.full_code}{" "}
        </p>

        {isNewComponent.value && (
          <FormNewComponent
            defaultValue={{
              name: "",
              type: "",
            }}
            projectId={projectDetail.id}
            onCloseModal={onCloseModalFormNewComponent}
            cpnTypeOptions={cpnTypeOptions.filter((el) => el.label !== "All")}
            onUpdateData={onUpdateComponent}
            folderId={idFolder}
          />
        )}
        {isNewFolder.value && (
          <FormNewFolder
            projectId={projectDetail.id}
            onUpdateData={onUpdateFolder}
            onCloseModal={onCloseModalFormNewFolder}
          />
        )}
        {isOpenFormDialog.value && (
          <FormProject
            openModal={isOpenFormDialog.value}
            handleChangeData={handleChangeData}
            defaultRequest={{
              id: projectDetail.id,
              name: projectDetail.name,
              description: projectDetail.description,
              selectCategories:
                projectDetail.categories && projectDetail.categories.length
                  ? projectDetail.categories.map((el) => {
                      return {
                        id: el.id,
                        name: el.name,
                        label: el.name,
                        value: el.id,
                      }
                    })
                  : [],
            }}
            onCloseModal={() => isOpenFormDialog.setValue(false)}
          />
        )}

        {isArchive.value && (
          <ModalDelete
            onCloseModal={() => isArchive.setValue(false)}
            title={`Are you sure to ${
              projectDetail.is_archived === 0 ? "archive" : "unarchive"
            } this project?`}
            label="Confirmation"
            onSubmit={onArchiveProject}
            titleButton={
              projectDetail.is_archived === 0 ? "Archive" : "Unarchive"
            }
            content=""
          />
        )}
        {isShare.value && (
          <FormShare
            onCloseModal={onCloseFormShare}
            projectId={projectDetail.id}
          />
        )}
        {isDeleteProject.value && (
          <ModalDelete
            onCloseModal={() => isDeleteProject.setValue(false)}
            title={`Are you sure to delete this project?`}
            label="Confirmation"
            onSubmit={onDeleteProject}
            titleButton="Delete"
            content=""
          />
        )}
      </PermissionProjectPage.Provider>
    )
  }

  return (
    <PageLayout
      childrenHeader={<Header projectDetail={projectDetail} />}
      heightHeader={48}
      minWidth="min-w-[1110px]"
      containerClass="relative"
    >
      {renderLayout()}
    </PageLayout>
  )
}

export default ProjectComponent
