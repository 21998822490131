import Axios, { AxiosResponse } from "axios"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import {
  CreateBuildRequest,
  ProjectBuildDetail,
} from "pages/project-component/types"
import { STATUS_RESPONSE } from "types"
import {
  CreateAdditionalRequest,
  FormVendorRequest,
  InviteeBuilComponentDetail,
  ProjectBuildAdditionalDetail,
  ProjectBuildExtraDetail,
  ProjectBuildInvitessDetail,
  ProjectBuildKeyResponse,
  ProjectComponentBuildDetail,
  SendComponentInviteeRequest,
  WorkedVendor,
} from "./project-build.type"
import {
  BuildConversationDetail,
  emptyBuildConversationDetail,
} from "pages/conversations/conversations.type"
import { STATUS_PROJECT_ROLE } from "pages/projects/types"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { activityLogHelper, ActivityLogType } from "helpers/activity_log"
import { encryptionHelper } from "helpers/encryption"
import _ from "lodash"
import { STATUS_BUILD_VALUE } from "components/Status/types"
import {
  getFileContentFromEnrypted,
  getPrivateAssetURI,
  getPrivateKeyByUserId,
  validateEncryptedFile,
} from "helpers/utils"
import { ACTION_WORKER } from "workers/type"
import { getWorkerLocalStorageData } from "workers/utils"

export const getProjectBuildMiddleware = async (
  idBuild: string,
  params?: {}
) => {
  const response = await Axios.get<{
    data: ProjectComponentBuildDetail
  }>(`/api/project-build/${idBuild}`, {
    params,
  })
  return response.data.data
}

export const postAddBuildMiddleware = (
  request: CreateBuildRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/create`, {
    ...request,
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.CreateBuild, {}),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? MESSENGER_NOTIFICATION.POST_BUILD_ERROR
      )
    })
}
export const putProjectBuildMiddleware = (
  idBuild: string,
  request: {
    project_component_ids: string[]
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.put(`/api/project-build/${idBuild}/update`, {
    ...request,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.AddBuildComponent,
      {}
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.UPDATE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_BUILD_ERROR
      )
    })
}

export const deleteComponentInBuildMiddleware = (
  idProjectBuildComponent: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(
    `/api/project-build/delete-component/${idProjectBuildComponent}`,
    {
      data: {
        log: activityLogHelper.toEncryptedMessage(
          ActivityLogType.DeleteBuildComponent,
          {}
        ),
      },
    }
  )
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_COMPONENT_IN_BUILD_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_COMPONENT_IN_BUILD_ERROR
      )
    })
}
export const duplicateBuildMiddleware = (
  idBuild: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectBuildDetail
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/duplicate`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.DuplicateBuild,
      {}
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.DUPLICATE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DUPLICATE_BUILD_ERROR
      )
    })
}
export const archiveBuildMiddleware = (
  idBuild: string,
  isArchive: boolean,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: ProjectBuildDetail
  ) => void
) => {
  console.log(isArchive)
  Axios.post(`/api/project-build/${idBuild}/archive`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.ArchivedProjectBuild,
      { archive: isArchive ? "unarchived" : "archived" }
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          isArchive
            ? MESSENGER_NOTIFICATION.UN_ARCHIVE_BUILD_SUCCESS
            : MESSENGER_NOTIFICATION.ARCHIVE_BUILD_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ||
          (isArchive
            ? MESSENGER_NOTIFICATION.UN_ARCHIVE_BUILD_ERROR
            : MESSENGER_NOTIFICATION.ARCHIVE_BUILD_ERROR)
      )
    })
}
export const postCopyLinkProjectBuild = (
  idBuild: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: {
      url: string
    }
  ) => void
) => {
  Axios.post(`/api/project-build/${idBuild}/share`)
    .then(
      (
        response: AxiosResponse<{
          data: {
            url: string
          }
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.POST_COPY_LINK_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.POST_COPY_LINK_ERROR
      )
    })
}

export const postProjectBuildStatusMiddleware = (
  idBuild: string,
  data: {
    status: string
    reason: string
  },
  labelStatus: string,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void,
  previousStatus: string
) => {
  Axios.post(`/api/project-build/${idBuild}/status`, {
    ...data,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.ChangeBuildStatus,
      { previousStatus, currentStatus: STATUS_BUILD_VALUE[data.status] }
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          `${labelStatus} successfully!`,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? `${labelStatus} failed!`
      )
    })
}
const decryptInfoData = (
  response: any,
  conversationId?: string,
  isAdditional = false
) => {
  return Promise.all(
    response.data.data.map(async (item) => {
      const decrypted = await encryptionController().decrypt(item.description, {
        dataType: "string",
        type: isAdditional ? "build_additional_info" : "project",
        relationId: isAdditional ? item.project_build_id : undefined,
      })
      const fileDatas = await Promise.all(
        item.files.map(async (file) => {
          const fileUrl = getPrivateAssetURI(
            file.file,
            conversationId ? { conversation_id: conversationId } : {}
          )
          const base64Str = await getFileContentFromEnrypted(
            fileUrl,
            new Date().toISOString(),
            true,
            undefined,
            { resutlType: "base64" },
            undefined,
            item.project_build_id
          )
          return {
            ...file,
            base64Str,
          }
        })
      )
      const includeImageIntoText = (content: string, files: any) => {
        let newContent = content
        files.forEach((rawFile) => {
          newContent = newContent.replace(rawFile.id, rawFile.base64Str)
        })
        return newContent
      }
      return {
        ...item,
        description: includeImageIntoText(decrypted, fileDatas),
      }
    })
  )
}
export const getProjectBuildAdditionalInfoMiddleware = async (
  idProjectBuild: string,
  idConversation?: string
) => {
  let url = `/api/project-build/${idProjectBuild}/get-list-additional-info`
  if (idConversation) {
    url += `?conversation_id=${idConversation}`
  }
  const response: AxiosResponse<{
    data: ProjectBuildAdditionalDetail[]
    encryptedKeys: {
      encrypted_key: string
      project_build_id: string
      additional_id: string
    }[]
  }> = await Axios.get(url)

  const projectEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
  )

  console.log(`-------START GET ADDITIONAL INFO-----------`)
  if (response.data.encryptedKeys && response.data.encryptedKeys.length > 0) {
    const privateKey: any = await getPrivateKeyByUserId()
    const buildKeyLocal: Record<string, string> = {}
    response.data.encryptedKeys.forEach((buildKey) => {
      if (buildKey.additional_id) {
        const projectKey = projectEncryptionKeys[buildKey.additional_id]
        if (projectKey) {
          buildKeyLocal[buildKey.project_build_id] = encryptionHelper.decrypt(
            projectKey,
            buildKey.encrypted_key
          ) as string
        }
      } else {
        try {
          const decryptedKey = privateKey.decrypt(
            buildKey.encrypted_key,
            "RSA-OAEP"
          )
          buildKeyLocal[buildKey.project_build_id] = decryptedKey
        } catch (error) {
          buildKeyLocal[buildKey.project_build_id] = buildKey.encrypted_key
        }
      }
    })

    console.log("Build Key", buildKeyLocal[idProjectBuild])
    console.log(`-------END GET ADDITIONAL INFO"-----------`)

    localStorage.setItem(
      EncryptionKeys.buildAdditionalInfoEncryptionKeys,
      JSON.stringify(buildKeyLocal)
    )
  }

  return decryptInfoData(response, idConversation, true) as any
}
export const getProjectBuildExtraInfoMiddleware = async (
  idProjectBuild: string
) => {
  const response: AxiosResponse<{
    data: ProjectBuildExtraDetail[]
  }> = await Axios.get(
    `/api/project-build/${idProjectBuild}/get-list-extra-info`
  )

  const currentProjectEncryptionKey = localStorage.getItem(
    EncryptionKeys.currentProjectEncryptionKey
  ) as string
  console.log(`-------START GET EXTRA INFO-----------`)

  console.log("Key", currentProjectEncryptionKey)
  console.log(`-------END GET EXTRA INFO"-----------`)

  return decryptInfoData(response) as any
}

export const postProjectBuildCreateAdditionalInfoMiddleware = async (
  worker: Worker,
  idBuild: string,
  data: CreateAdditionalRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const multipart = new FormData()
  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: data.files,
      localStorageData: getWorkerLocalStorageData(),
      content: data.description,
      contentOption: {
        type: "build_additional_info",
        dataType: "string",
        relationId: idBuild,
      },
      fileOption: {
        type: "build_additional_info",
        dataType: "file",
        relationId: idBuild,
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments, contentKey } = e.data.payload
      attachments.forEach((attachment) => {
        multipart.append("attachments", attachment.blob, attachment.fileName)
      })

      multipart.append(
        "data",
        JSON.stringify({
          ...data,
          files: fileHashs,
          description: contentKey,
          log: activityLogHelper.toEncryptedMessage(
            ActivityLogType.UpdateAdditionalOrExtraInfo,
            { model: "additional" }
          ),
        })
      )
      Axios.post(
        `/api/project-build/${idBuild}/create-additional-info`,
        multipart
      )
        .then(async (response: AxiosResponse) => {
          callback(STATUS_RESPONSE.SUCCESS, "")
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                  project_build_id: idBuild,
                },
                response.data.data.project_id
              )
              return true
            })
          )
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.CREATE_ADDITIONAL_INFO_ERROR
          )
        })
    }
  }
}
export const deleteProjectBuildCreateAdditionalInfoMiddleware = (
  idAdditional: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/additional-info/${idAdditional}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_ADDITIONAL_INFO_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_ADDITIONAL_INFO_ERROR
      )
    })
}
export const postProjectBuildCreateExtraInfoMiddleware = async (
  worker: Worker,
  idBuild: string,
  data: CreateAdditionalRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const multipart = new FormData()
  worker.postMessage({
    action: ACTION_WORKER.START,
    payload: {
      files: data.files,
      localStorageData: getWorkerLocalStorageData(),
      content: data.description,
      contentOption: {
        type: "project",
        dataType: "string",
      },
      fileOption: {
        type: "project",
        dataType: "file",
      },
    },
  })

  worker.onmessage = async function (e) {
    if (e.data.action === ACTION_WORKER.FINISH) {
      const { fileHashs, attachments, contentKey } = e.data.payload
      attachments.forEach((attachment) => {
        multipart.append("attachments", attachment.blob, attachment.fileName)
      })
      multipart.append(
        "data",
        JSON.stringify({
          ...data,
          files: fileHashs,
          description: contentKey,
          log: activityLogHelper.toEncryptedMessage(
            ActivityLogType.UpdateAdditionalOrExtraInfo,
            { model: "extra" }
          ),
        })
      )
      Axios.post(`/api/project-build/${idBuild}/create-extra-info`, multipart)
        .then(async (response: AxiosResponse) => {
          await Promise.all(
            response.data.data.files.map(async (file) => {
              const found = fileHashs.find(
                (item) => item.file_name === file.file_name
              )
              await validateEncryptedFile(
                found?.file_hash || "",
                {
                  file_key: file.file_key,
                },
                response.data.data.project_id
              )
              return true
            })
          )
          callback(STATUS_RESPONSE.SUCCESS, "")
        })
        .catch((error) => {
          callback(
            STATUS_RESPONSE.ERROR,
            error.response?.data?.message ??
              MESSENGER_NOTIFICATION.CREATE_EXTRA_INFO_ERROR
          )
        })
    }
  }
}
export const deleteProjectBuildCreateExtraInfoMiddleware = (
  idExtra: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/extra-info/${idExtra}/delete`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_EXTRA_INFO_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_EXTRA_INFO_ERROR
      )
    })
}

export const getProjectBuildInviteeMiddleware = async (
  idProjectBuild: string
) => {
  const response: AxiosResponse<{
    data: ProjectBuildInvitessDetail[]
    conversationKeys: {
      conversation_id: string
      encrypted_key: string
    }[]
  }> = await Axios.get(`/api/project-build/${idProjectBuild}/get-list-invitee`)

  const currentProjectEncryptionKey = localStorage.getItem(
    EncryptionKeys.currentProjectEncryptionKey
  ) as string

  if (response.data.conversationKeys && response.data.conversationKeys.length) {
    const conversationEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
    )

    const newConversationKeys: Record<string, string> = {}
    response.data.conversationKeys.forEach((conversationKey) => {
      newConversationKeys[conversationKey.conversation_id] =
        currentProjectEncryptionKey
          ? (encryptionHelper.decrypt(
              currentProjectEncryptionKey,
              conversationKey.encrypted_key
            ) as string)
          : ""
    })
    localStorage.setItem(
      EncryptionKeys.conversationEncryptionKeys,
      JSON.stringify({
        ...conversationEncryptionKeys,
        ...newConversationKeys,
      })
    )
  }

  return response.data.data
}

export const postProjectBuildCreateInviteeMiddleware = async (
  idBuild: string,
  data: FormVendorRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const currentProjectEncryptionKey = localStorage.getItem(
    EncryptionKeys.currentProjectEncryptionKey
  )
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const conversationEncryptionKey = encryptionHelper.createRandomKey()
  const enableEncrypted = localStorage.getItem("enabled_encrypted")

  let dataToPost: any = data
  if (currentProjectEncryptionKey && enableEncrypted === "1") {
    const encryptedEncryptionKey = encryptionHelper.encrypt(
      currentProjectEncryptionKey,
      conversationEncryptionKey
    ) as string

    // Share conversation key to contributors
    const convoContributorKey = encryptionHelper.encrypt(
      currentProjectEncryptionKey || "",
      conversationEncryptionKey
    )
    dataToPost = {
      ...data,
      encryptedKey: encryptedEncryptionKey,
      log: activityLogHelper.toEncryptedMessage(ActivityLogType.AddInvitee, {}),
      convoContributorKey,
    }
  }
  Axios.post(`/api/project-build/${idBuild}/create-invitee`, dataToPost)
    .then((response: AxiosResponse) => {
      if (!_.isEmpty(currentProjectEncryptionKey) && enableEncrypted === "1") {
        localStorage.setItem(
          EncryptionKeys.conversationEncryptionKeys,
          JSON.stringify({
            ...conversationEncryptionKeys,
            [response.data.data.conversation_id]: conversationEncryptionKey,
          })
        )
      }
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.CREATE_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.CREATE_INVITEE_SUCCESS
      )
    })
}
export const putProjectBuildUpdateInviteeMiddleware = (
  idInvitee: string,
  data: FormVendorRequest,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.put(`/api/project-build/update-invitee/${idInvitee}`, {
    ...data,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.UpdateInvitee,
      {}
    ),
  })
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.UPDATE_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.UPDATE_INVITEE_ERROR
      )
    })
}
export const getProjectBuildInviteeComponentMiddleware = async (
  idInvitee: string
) => {
  const response: AxiosResponse<{
    data: InviteeBuilComponentDetail[]
  }> = await Axios.get(
    `/api/project-build/invitee/${idInvitee}/get-list-rest-invitee-build-component`
  )
  return response.data.data
}
export const deleteProjectBuildMiddleware = (
  idProjectBuild: string,
  nameBuild: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.delete(`/api/project-build/${idProjectBuild}/delete`)
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `Delete ${nameBuild} successfully!`)
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? `Delete ${nameBuild} failed!`
      )
    })
}
export const postProjectBuildInviteeAddComponentMiddleware = (
  idInvitee: string,
  projectBuildComponentId: string,
  componentCode: string,
  conversationId: string,
  dataComponentRequest: SendComponentInviteeRequest,
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    data?: InviteeBuilComponentDetail
  ) => void
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const conversationEncryptionKey = conversationEncryptionKeys[conversationId]
  const commentLog = activityLogHelper.toEncryptedMessage(
    ActivityLogType.CommentAddInviteeBuildComponent,
    { componentCode },
    conversationEncryptionKey
  )
  Axios.post(
    `/api/project-build/invitee/${idInvitee}/component/${projectBuildComponentId}/add`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.AssignComponent,
        {}
      ),
      commentLog,
      dataComponentRequest,
    }
  )
    .then(
      (
        response: AxiosResponse<{
          data: InviteeBuilComponentDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          MESSENGER_NOTIFICATION.ADD_COMPONENT_INVITEE_SUCCESS,
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.ADD_COMPONENT_INVITEE_ERROR
      )
    })
}
export const deleteProjectBuildInviteeAddComponentMiddleware = (
  buildInviteComponentId: string,
  componentCode: string,
  conversationId: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  const conversationEncryptionKey = conversationEncryptionKeys[conversationId]
  const commentLog = activityLogHelper.toEncryptedMessage(
    ActivityLogType.CommentDeleteInviteeBuildComponent,
    { componentCode },
    conversationEncryptionKey
  )
  Axios.put(`/api/project-build/invitee/component/${buildInviteComponentId}`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.DeleteInviteeComponent,
      {}
    ),
    commentLog,
  })
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.DELETE_COMPONENT_INVITEE_ERROR
      )
    })
}
export const postCopyLinkInviteeMiddleware = (
  idInvitee: string,
  generateDataKeyPairUsers: {
    publicKey: string
    encryptedPrivateKey: string
    userId: string
  }[],
  callback: (type: STATUS_RESPONSE, messenger: string, link?: string) => void,
  conversationId: string,
  ccEmail?: string,
  listUser?: string[]
) => {
  const currentProjectEncryptionKey = localStorage.getItem(
    EncryptionKeys.currentProjectEncryptionKey
  ) as string
  const baseURL = window.location.origin
  if (!currentProjectEncryptionKey) {
    callback(
      STATUS_RESPONSE.SUCCESS,
      `Copy link successfully!`,
      `${baseURL}/conversations?conversationId=${conversationId}&tab=${
        ccEmail ? "cc-to-me" : "to-me"
      }`
    )
    return
  }
  Axios.post(`/api/project-build/invitee/${idInvitee}/copy-link`, {
    listUser,
    ccEmail,
    generateDataKeyPairUsers,
  })
    .then((response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        `Copy link successfully!`,
        response.data.data.link
      )
    })
    .catch((errorSend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorSend.response?.data?.message ?? `Copy link failed!`
      )
    })
}
export const postProjectBuildSendInviteeMiddleware = (
  idInvitee: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  listUser: string[],
  generateDataKeyPairUsers: {
    publicKey: string
    encryptedPrivateKey: string
    userId: string
  }[]
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/send`, {
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.SendInvitation,
      {}
    ),
    listUser,
    generateDataKeyPairUsers,
  })
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `${type} invite successfully!`)
    })
    .catch((errorSend) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorSend.response?.data?.message ?? `${type} invite failed!`
      )
    })
}
export const postProjectBuildInviteeActivateMiddleware = (
  idInvitee: string,
  type: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void,
  invitee: string,
  generateDataKeyPairUsers: {
    publicKey: string
    encryptedPrivateKey: string
    userId: string
  }[]
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/activate`, {
    log: activityLogHelper.toEncryptedMessage(ActivityLogType.ActivateInvitee, {
      invitee,
    }),
    generateDataKeyPairUsers,
  })
    .then((_response: AxiosResponse) => {
      callback(STATUS_RESPONSE.SUCCESS, `${type} invitee successfully!`)
    })
    .catch((errorActivate) => {
      callback(
        STATUS_RESPONSE.ERROR,
        errorActivate.response?.data?.message ?? `${type} invite failed!`
      )
    })
}

export const getProjectBuildListInviteeByBuildComponentMiddleware = async (
  projectBuildComponentId: string
) => {
  const response: AxiosResponse<{
    data: {
      id: string
      name: string
      email: string
    }[]
  }> = await Axios.get(
    `/api/project-build/get-list-invitee-by-build-component/${projectBuildComponentId}`
  )
  return response.data.data
}

export const putProjectBuildToInProductionMiddleware = async (
  idProductBuild: string,
  dataRequest: {
    data: {
      project_build_component_id: string
      invitee_id: string
    }[]
    status: string | number
  },
  components: any[],
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void,

  buildCode?: string,
  status?: string
) => {
  let temp: any = []
  components.forEach((component) => {
    const found = dataRequest.data.find(
      (selected) =>
        selected.project_build_component_id ===
        component.projectBuildComponentId
    )
    const restInvitees = component.invitees.filter(
      (item) => item.id !== found?.invitee_id
    )
    temp = temp.concat(
      restInvitees.map((invitee) => ({
        ...invitee,
        componentCode: `${component.code}/${component.version}`,
        conversation_id: invitee.conversation_id,
      }))
    )
  })
  const userId = localStorage.getItem("user_id") || ""
  const commentLogs = await Promise.all(
    temp.map(async (log) => {
      return {
        content: await encryptionController().encrypt(
          activityLogHelper.toLogMessage(
            ActivityLogType.CommentToInProduction,
            log
          ),
          {
            dataType: "string",
            type: "conversation",
            relationId: log.conversation_id,
          }
        ),
        created_by: userId,
        model: "conversations",
        relation_id: log.conversation_id,
        is_notification: true,
      }
    })
  )
  Axios.put(`/api/project-build/${idProductBuild}/to-in-production`, {
    ...dataRequest,
    log: {
      changeStatusLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.ChangeBuildStatusToProductionOrInProgress,
        { buildCode, status }
      ),
      acceptInviteeLog: activityLogHelper.toEncryptedMessage(
        ActivityLogType.AcceptedInvitee,
        {}
      ),
      commentLogs,
    },
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          "Build status to in production successfully!",
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Build status to in production failed!"
      )
    })
}

export const postProjectBuildInviteeSyncMiddleware = (
  idInvitee: string,
  callback: (type: STATUS_RESPONSE, messenger: string) => void
) => {
  Axios.post(`/api/project-build/invitee/${idInvitee}/sync`)
    .then((_response: AxiosResponse) => {
      callback(
        STATUS_RESPONSE.SUCCESS,
        MESSENGER_NOTIFICATION.SYNC_DATA_INVITEE_SUCCESS
      )
    })
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ??
          MESSENGER_NOTIFICATION.SYNC_DATA_INVITEE_ERROR
      )
    })
}

export const postProjectBuildComponentSyncMiddleware = (
  idProjectBuildComponent: string,
  callback: (type: STATUS_RESPONSE, messenger: string, data?: any) => void,
  componentCode?: string,
  versionCode?: string
) => {
  const conversationEncryptionKeys = JSON.parse(
    localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
  )
  Axios.get(
    `/api/project-build/component/${idProjectBuildComponent}/get-conversations`
  ).then((response: AxiosResponse) => {
    const commentLogs = response.data.data.map((conversationId: string) => {
      const conversationEncryptionKey =
        conversationEncryptionKeys[conversationId]
      return {
        content: activityLogHelper.toEncryptedMessage(
          ActivityLogType.CommentSyncDataFromMain,
          { componentCode, versionCode },
          conversationEncryptionKey
        ),
        created_by: localStorage.getItem("user_id"),
        model: "conversations",
        relation_id: conversationId,
        is_notification: true,
      }
    })
    Axios.post(`/api/project-build/component/${idProjectBuildComponent}/sync`, {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.SyncFromMain,
        {}
      ),
      commentLogs,
    })
      .then(
        (
          response: AxiosResponse<{
            data: any
          }>
        ) => {
          callback(
            STATUS_RESPONSE.SUCCESS,
            "Update to the latest version from the master branch successfully!",
            response.data.data
          )
        }
      )
      .catch((error) => {
        callback(
          STATUS_RESPONSE.ERROR,
          error.response?.data?.message ??
            "Update to the latest version from the master branch failed!"
        )
      })
  })
}

export const putProjectBuildNameMiddleware = (
  idProjectBuild: string,
  request: {
    name: string
  },
  callback: (
    type: STATUS_RESPONSE,
    messenger: string,
    dataRes?: ProjectComponentBuildDetail
  ) => void
) => {
  Axios.put(`/api/project-build/${idProjectBuild}/update-name`, {
    ...request,
    log: activityLogHelper.toEncryptedMessage(
      ActivityLogType.UpdateBuildName,
      {}
    ),
  })
    .then(
      (
        response: AxiosResponse<{
          data: ProjectComponentBuildDetail
        }>
      ) => {
        callback(
          STATUS_RESPONSE.SUCCESS,
          "Updated project build successfully!",
          response.data.data
        )
      }
    )
    .catch((error) => {
      callback(
        STATUS_RESPONSE.ERROR,
        error.response?.data?.message ?? "Updated project build failed!"
      )
    })
}

export const getWorkedVendors = async () => {
  return Axios.get<{ data: WorkedVendor[] }>(
    "/api/project-build/worked-vendors"
  ).then((res) => res.data)
}

export const deleteBuildAttachment = async (id: string) => {
  return Axios.delete(`/api/project-build/${id}/delete-attachment`).then(
    (res) => res.data
  )
}

export const duplicateBuilInBuildOverviewdMiddleware = async (
  idBuild: string
) => {
  const res = await Axios.post<{ data: ProjectBuildDetail }>(
    `/api/project-build/${idBuild}/duplicate`,
    {
      log: activityLogHelper.toEncryptedMessage(
        ActivityLogType.DuplicateBuild,
        {}
      ),
    }
  )
  const data = res.data.data
  const finalData: BuildConversationDetail = {
    ...emptyBuildConversationDetail,
    id: data.id,
    project_id: data.project_id,
    code: data.code,
    components: JSON.parse(data.component_types),
    project_code: data.project_code,
    is_read: false,
    is_archived: 0,
    updated_by: "by <b>me</b>",
    project_role: STATUS_PROJECT_ROLE.EDITOR,
    project_name: data.project_name,
    users: [],
    status: data.status,
    updated_at: Date().toString(),
  }
  return finalData
}

export const updateInviteeModifyComponentPermissionMiddleWare = async (
  invitee_id: string,
  can_modify_component: 0 | 1
) => {
  const res = await Axios.put(
    `api/project-build/invitee/${invitee_id}/update-permission`,
    {
      can_modify_component,
      log: activityLogHelper.toEncryptedMessage(
        can_modify_component
          ? ActivityLogType.AllowInviteeEditComponent
          : ActivityLogType.DisallowInviteeEditComponent,
        {}
      ),
    }
  )
  return res.data
}

export const getProjectBuildKeysMiddleware = async () => {
  const response: AxiosResponse<ProjectBuildKeyResponse> = await Axios.get(
    `/api/project-build/keys`
  )

  return response.data.data
}

export const postUploadFileInviteeMiddleware = async (request: {
  blob: Blob
  file_hash: string
  file_name: string
}) => {
  try {
    const formData = new FormData()
    formData.append("file", request.blob, request.file_name)
    formData.append("file_hash", request.file_hash)
    formData.append("file_name", request.file_name)
    await Axios.post(`/api/project-build/invitee/upload-file`, formData)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const postReShareDataToInviteeMiddleware = async (
  projectBuildId: string,
  logs: {
    activity: string
    comment: string
    conversation_id: string
    component_code: string
  }[],
  inviteHashKeys: {
    project_component_history_id: string
    invitee_id: string
    user_id: string
    new_project_component_history_id: string
  }[]
) => {
  const response = await Axios.post(
    `/api/project-build/${projectBuildId}/invitee/re-share-key-data`,
    {
      logs,
      inviteHashKeys,
    }
  )
  return response.data.data
}
