import Button from "components/Button/Button"
import {
  encryptionController,
  EncryptionKeys,
} from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { useBoolean, useNumber } from "helpers/hooks"
import { useEffect, useState } from "react"
import { configureStore } from "stores/configureStore"
import { startLoading, closeLoading } from "reducers/loading"

import {
  getFileContent,
  getPrivateKeyByUserId,
  getProjectBackupAssetURI,
  validateConversationNoteFile,
} from "helpers/utils"
import ProgressBar from "components/ProgressBar/ProgressBar"
import {
  getUserNoteFilesMiddleware,
  postSyncNoteFileMiddleware,
  updateNotesMiddleware,
} from "pages/conversations/conversations.api"
import ConversationSyncTable from "./ConversationSyncTable"
import moment from "moment"
import { toast } from "react-toastify"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { postFileHashKeyMiddleware } from "pages/projects/services/api"
import { cloneDeep } from "lodash"

const ConversationSync = (props: {
  onchangeDoneSyncConversationNote: (isDone: boolean) => void
  isStartSync: boolean
}) => {
  const isLoading = useBoolean()
  const isShowProgressBar = useBoolean(false)
  const totalNumber = useNumber(0)
  const doneNumber = useNumber(0)
  let log = "File path, Hash of raw, Hash after decrypting, Status, Time"
  const [data, setData] = useState<any>([])
  const [notes, setNotes] = useState<
    {
      note: string
      conversation_id: string
      id: string
    }[]
  >([])
  useEffect(() => {
    // console.log(props.isStartSync)
    if (!props.isStartSync) return
    const pendingNumber = data.filter((item) => !item.is_synced).length
    if (pendingNumber === 0) {
      props.onchangeDoneSyncConversationNote(true)
      localStorage.setItem("isDoneSyncConversationNote", "1")
    } else {
      props.onchangeDoneSyncConversationNote(false)
      localStorage.setItem("isDoneSyncConversationNote", "0")
      {
        handleOnclickSyncFile()
      }
    }
  }, [props.isStartSync])

  const getConversationNoteFiles = async () => {
    const userNoteFiles = await getUserNoteFilesMiddleware()
    const { files, notes } = userNoteFiles
    if (
      !files.filter((item) => !item.is_synced).length &&
      !notes.filter((item) => !item.is_synced).length
    ) {
      props.onchangeDoneSyncConversationNote(true)
      localStorage.setItem("isDoneSyncConversationNote", "1")
    }

    totalNumber.setValue(files.length)
    doneNumber.setValue(files.filter((item) => item.is_synced).length)
    let newFiles: {
      file_key: string
      is_synced: boolean
    }[] = []
    if (notes.length) {
      newFiles.push({
        file_key: "Note",
        is_synced:
          notes.filter((note) => note.is_synced).length === notes.length
            ? true
            : false,
      })
      setNotes(notes.filter((note) => !note.is_synced))
    }
    newFiles = newFiles.concat(files)
    setData(newFiles)
    isShowProgressBar.setValue(false)
  }

  useEffect(() => {
    getConversationNoteFiles()
  }, [])

  const syncConversationNoteFiles = async (
    files: { file_key: string; is_synced: boolean }[]
  ) => {
    const userVaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    const privateKeyByUserId = (await getPrivateKeyByUserId(false)) as string

    if (!privateKeyByUserId) {
      toast(
        <LabelNotificationPage
          messenger={"Private key not found!"}
          type={"error"}
        />
      )
      return
    }
    for (let j = 0; j < files.length; j++) {
      const file = files[j]
      if (file.is_synced) {
        continue
      }
      console.log("-------START SYNC CONVERSATION NOTE FILE-----------")

      const fileUrl = getProjectBackupAssetURI(file.file_key)
      const fileContentResponse = await getFileContent(fileUrl, true)
      const fileData = fileContentResponse.content
      const contentType = fileContentResponse.content_type
      const fileDataText = await fileData.text()

      const signatureAndHashFile = encryptionHelper.createSignatureAndHashFile(
        fileDataText,
        true,
        privateKeyByUserId
      )
      console.log("fileKey: ", file.file_key)
      console.log(
        `${moment().format("YYYY-MM-DD HH:mm:ss")} - signature: `,
        signatureAndHashFile.signature
      )
      const fileKeyParts = file.file_key.split("/")
      const fileName = fileKeyParts[fileKeyParts.length - 1]
      const fileFormData = new FormData()
      const encryptedFileData = await encryptionController().encrypt(fileData, {
        dataType: "file",
        type: "conversation_note",
        encryptionKey: userVaultKey,
      })
      const newBlob = new Blob([encryptedFileData], {
        type: contentType,
      })
      fileFormData.append("file", newBlob, fileName)
      fileFormData.append("key", file.file_key)
      await postFileHashKeyMiddleware(
        file.file_key,
        signatureAndHashFile.signature
      )
      await postSyncNoteFileMiddleware(fileFormData).then(async () => {
        doneNumber.setValue((prev) => prev + 1)

        const logData = await validateConversationNoteFile(
          signatureAndHashFile.signature,
          file
        )
        if (logData) {
          log = log + logData
        }
      })
      console.log("-------END SYNC CONVERSATION NOTE FILE-----------")
    }
    getConversationNoteFiles()
    // downloadLogCsv(log)
  }
  const downloadLogCsv = (data: string) => {
    try {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "text/csv",
        })
      )

      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "conversation_note_log.csv")
      document.body.appendChild(link)
      link.click()
      link?.parentNode?.removeChild(link)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnclickSyncFile = async () => {
    isLoading.setValue(true)
    let isError = false
    const newFiles = cloneDeep(data)

    if (notes.length) {
      configureStore.dispatch(startLoading())
      for (const note in notes) {
        if (!isError) {
          await updateNotesMiddleware(
            notes[note].conversation_id,
            notes[note].note
          )
            .then((_res) => {})
            .catch((error) => {
              isError = true
              toast(
                <LabelNotificationPage
                  messenger={error.response?.data?.message}
                  type="error"
                />
              )
            })
        }
      }
      if (isError) {
        return
      }
      const noteIndex = newFiles.findIndex((item) => item.file_key === "Note")
      if (noteIndex > -1) {
        newFiles[noteIndex].is_synced = true
      }
    }
    isShowProgressBar.setValue(true)
    configureStore.dispatch(startLoading())

    //Sync conversation note files here
    await syncConversationNoteFiles(newFiles)
    /* ------------------------------ */
  }
  return data.length > 0 ? (
    <div className="p-6 pt-0">
      <h2 className="text-[16px] font-semibold lh-26 text-black mb-[8px] mt-[24px]">
        Sync Conversation notes status
      </h2>
      <ProgressBar
        title={"Sync note files ..."}
        isShow={isShowProgressBar.value}
        currentValue={doneNumber.value || 0}
        totalValue={totalNumber.value || 0}
      />
      <div className="text-black bg-white border border-border-gray rounded-md overflow-auto">
        <ConversationSyncTable data={data} />
      </div>
    </div>
  ) : null
}

export default ConversationSync
