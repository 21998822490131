import { MODEL_ACTIVITY_LOG } from "components/activity-log/activity-log.type"
import ActivityLogDefault from "components/activity-log/ActivityLogDefault"
import Breadcrumbs from "components/Breadcrumbs"
import HeaderBreadcrumbCard from "components/Breadcrumbs/HeaderBreadcrumbCard"
import { BreadcrumbsDetail } from "components/Breadcrumbs/types"
import {
  HeaderBreadcrumbInfo,
  HeaderContent,
} from "components/HeaderInfo/HeaderInfo"
import { useTippyLayout } from "components/TippyCustomzie"
import { PATH } from "constants/path"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { formatDate, formatDateForConversationMessage } from "helpers/utils"
import LabelHeader, {
  getContentLabelStyleProps,
} from "pages/project-component/molecules/LabelHeader"
import { ProjectDetail } from "pages/projects/types"
import React from "react"
import { ProjectComponentBuildDetail } from "../project-build.type"
import { getWorkerLocalStorageData } from "workers/utils"
import { encryptionHelper } from "helpers/encryption"

interface HeaderProps {
  projectDetail: ProjectDetail
  projectBuild: ProjectComponentBuildDetail
  updatedAtBuild: string
}
const ProjectBuildHeader = (props: HeaderProps) => {
  const { projectDetail, projectBuild } = props
  const sizeWindow = useWindowSize()

  const listBreadcrumbs: BreadcrumbsDetail[] = [
    {
      name: projectDetail?.name,
      field: "idProject",
      path: PATH.projectComponent,
      value: projectDetail.id,
    },
    {
      name: projectBuild.name,
    },
  ]

  return (
    <div className="flex justify-between items-center h-full px-6">
      {sizeWindow.width < 1440 ? (
        <HeaderBreadcrumbCard
          renderBreadcrumb={<Breadcrumbs listBreadcrumbs={listBreadcrumbs} />}
          renderIDInfo={<HeaderItem {...props} />}
        />
      ) : (
        <React.Fragment>
          <Breadcrumbs listBreadcrumbs={listBreadcrumbs} overflow />

          <HeaderItem {...props} />
        </React.Fragment>
      )}
    </div>
  )
}

export default ProjectBuildHeader

const HeaderItem = (props: HeaderProps) => {
  const { projectBuild, projectDetail, updatedAtBuild } = props
  const { isMobile } = useWindowSize()
  const showProjectPCBInfo = useBoolean()
  const { TippyLayout /* closeLayout, openLayout  */ } = useTippyLayout()

  const contentLabelStyleProps = getContentLabelStyleProps(
    !isMobile && !!projectBuild.id
  )
  const { projectEncryptionKeys } = getWorkerLocalStorageData()

  const labelLastUpdate = updatedAtBuild
    ? `${formatDateForConversationMessage(updatedAtBuild)}`
    : "No update"

  const renderContent = () => (
    <>
      <LabelHeader
        {...contentLabelStyleProps}
        title="Project ID:"
        label={projectDetail.code}
        isClick
        path={`/project-detail/${projectDetail.id}`}
      />
      {projectBuild.id ? (
        <React.Fragment>
          <LabelHeader
            {...contentLabelStyleProps}
            title="Build ID:"
            label={projectBuild.code}
          />
          <LabelHeader
            {...contentLabelStyleProps}
            title="Created:"
            label={formatDate(projectBuild?.created_at, "DD MMM yyyy")}
          />
          <LabelHeader
            {...contentLabelStyleProps}
            title="Last update:"
            label={labelLastUpdate}
            isBorderRight={checkShowActivityLog()}
          />
        </React.Fragment>
      ) : null}
    </>
  )

  const renderPCBContent = () =>
    isMobile ? (
      <TippyLayout
        visible={showProjectPCBInfo.value}
        headerContent={
          <HeaderContent onClick={() => showProjectPCBInfo.setValue(false)} />
        }
        mainContent={renderContent()}
      >
        <LabelHeader
          title="Project Info"
          isBorderRight={checkShowActivityLog()}
          onClickTitle={() => {
            showProjectPCBInfo.setValue(true)
          }}
        />
      </TippyLayout>
    ) : (
      renderContent()
    )
  const checkShowActivityLog = () => {
    if (!projectBuild.id) {
      return false
    }
    if (!projectEncryptionKeys[projectBuild.project_id]) {
      return false
    }
    if (projectBuild?.id && projectBuild.buildKey?.encryptedKey) {
      const decryptedBuildKey = encryptionHelper.decrypt(
        projectEncryptionKeys[projectBuild.project_id],
        projectBuild?.buildKey?.encryptedKey
      )
      if (
        !decryptedBuildKey ||
        String(decryptedBuildKey).length !== 64 ||
        decryptedBuildKey === projectBuild?.buildKey?.encryptedKey
      ) {
        return false
      }
    }
    return true
  }
  const renderActivityLog = () => {
    if (!checkShowActivityLog()) {
      return null
    }
    return (
      <ActivityLogDefault
        model={MODEL_ACTIVITY_LOG.PROJECT_BUILDS}
        idRelation={projectBuild.id}
      />
    )
  }

  return (
    <HeaderBreadcrumbInfo className="px-0">
      {renderPCBContent()}
      {renderActivityLog()}
    </HeaderBreadcrumbInfo>
  )
}
