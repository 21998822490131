import { PATH } from "constants/path"
import { EncryptionKeys } from "controllers/EncryptionController"
import { encryptionHelper } from "helpers/encryption"
import { pushTo } from "helpers/history"
import { getPrivateKeyByUserId } from "helpers/utils"
import { isEmpty } from "lodash"
import {
  getUnreadBuildMiddleware,
  getUnreadConversationMiddleware,
} from "pages/auth/services/api"
import {
  acceptedSharingProjectMiddleware,
  createEncryptionKeyMiddleware,
} from "pages/projects/services/api"
import { ProjectDetail } from "pages/projects/types"
import { KeyEncryptionType } from "types"
import { useAppSelector } from "./useApp"

export const useShareInvitation = () => {
  const userInfo = useAppSelector((state) => state.userInfo)

  const acceptedSharingProject = async (
    projectShare: ProjectDetail,
    isRedirectProjectComponent = false
  ): Promise<boolean> => {
    if (projectShare.isProjectKeyEncrypted) {
      if (userInfo.id !== projectShare.created_by) {
        acceptedSharingProjectMiddleware(projectShare.id)
      }
      if (isRedirectProjectComponent) {
        pushTo(PATH.projectComponent, {
          idProject: projectShare.id,
        })
      }
      return true
    }
    const userVaultKey = localStorage.getItem(EncryptionKeys.userVaultKey) || ""
    //For project sharing
    const projectKey = projectShare.encryptedKey
    if (!projectKey) {
      if (isRedirectProjectComponent) {
        pushTo(PATH.projects)
      }
      return false
    }
    const privateKeyPem: any = await getPrivateKeyByUserId()
    const projectEncryptionKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    const decryptProjectKeyByPrivateKey = privateKeyPem.decrypt(
      projectKey,
      "RSA-OAEP"
    )
    if (decryptProjectKeyByPrivateKey) {
      localStorage.setItem(
        EncryptionKeys.projectEncryptionKeys,
        JSON.stringify({
          ...projectEncryptionKeys,
          [projectShare.id]: decryptProjectKeyByPrivateKey,
        })
      )
      localStorage.setItem(
        EncryptionKeys.currentProjectEncryptionKey,
        isEmpty(decryptProjectKeyByPrivateKey)
          ? ""
          : (decryptProjectKeyByPrivateKey as string)
      )

      const ownerEncryptedKey = encryptionHelper.encrypt(
        userVaultKey,
        decryptProjectKeyByPrivateKey
      )

      await createEncryptionKeyMiddleware({
        encrypted_key: ownerEncryptedKey,
        model: "projects",
        relation_id: projectShare.id,
        type: KeyEncryptionType.ModuleKey,
      })
    }

    await acceptedSharingProjectMiddleware(projectShare.id)
    if (isRedirectProjectComponent) {
      localStorage.removeItem("sharedProject")
      getUnreadBuildMiddleware()
      getUnreadConversationMiddleware()
      pushTo(PATH.projectComponent, {
        idProject: projectShare.id,
      })
    }
    return true
  }

  return { acceptedSharingProject }
}
