import { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import { useBoolean, useString } from "helpers/hooks"
import {
  getBuildComponentDetailMiddleware,
  getProjectComponentDetailMiddleware,
} from "pages/project-component/services/api"
import {
  ProjectComponentDetail,
  defaultProjectComponentDetail,
} from "pages/project-component/types"
import { TYPE_PROJECT_COMPONENT } from "pages/project-component/project-component.constant"
import { includes, isArray } from "lodash"
import {
  emptyInfoFileDetail,
  InfoFileDetail,
  BuildHistoryDetail,
  defaultBuildHistoryDetail,
  ComponentType,
} from "pages/project-component-detail/types"
import {
  getHistoriesBuildComponentConvoMiddleware,
  getIsReadBuildComponentMiddleware,
  getProjectComponentConversationBOMMiddleware,
  getProjectComponentHistoryMiddleware,
} from "pages/project-component-detail/services"
import { STATUS_TYPE_FILE_BUILD } from "pages/project-component-detail/build-history.constant"
import FileInviteePCBItem from "./FileInviteePCBItem"
import {
  getConversationMessagesMiddleware,
  getConversationRoleMiddleWare,
} from "pages/conversations/conversations.api"
import {
  BUILD_INVITEE_DETAIL_STATUS,
  CONVERSATION_ROLE,
} from "pages/conversations/conversations.type"
import { useAppSelector } from "hooks/useApp"
import InviteeBOMDetailCard from "./InviteeBOMDetailCard"
import { useInviteeComponent } from "./invitees-component.hook"
import {
  acceptedSharingConversationMiddleware,
  getProjectDetailMiddleware,
} from "pages/projects/services/api"
import PageInviteeComponent from "./PageInviteeComponent"
import {
  STATUS_BUILD,
  ProjectComponentStatus,
  BuildStatusNumber,
} from "components/Status/types"
import { SectionCustom } from "components/Section/SectionCustom"
import HistoryTreeCard from "components/HistoryTree/HistoryTreeCard"
import { findHistoryTreeDefault, isTypeFile } from "helpers/utils"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { STATUS_RESPONSE } from "types"
import { toast } from "react-toastify"
import {
  inviteeDeleteFileBOMMiddleware,
  inviteeUploadFileBOMMiddleware,
} from "./services/invitee.api"
import ModalDelete from "components/ModalCustom/ModalDelete"
import { MESSENGER_NOTIFICATION } from "constants/messenger"
import { useUploadFileWorkerController } from "workers/uploadFileWorkerController"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"

const InviteeBOMComponent = () => {
  const { workerUploadFile } = useUploadFileWorkerController()
  const params = useParams<{
    idProjectComponent: string
    idProjectBuildComponent: string
    idProjectComponentHistory: string | undefined
    idConversation: string
  }>()
  const locationInviteeComponentBOM = useLocation<any>()

  const userInfo = useAppSelector((state) => state.userInfo)
  const isLoading = useBoolean()
  const [componentDetail, setComponentDetail] =
    useState<ProjectComponentDetail>(defaultProjectComponentDetail)
  const [infoFileBOM, setInfoFileBOM] =
    useState<InfoFileDetail>(emptyInfoFileDetail)
  const [tableBOM, setTableBOM] = useState<any>([])
  const updatedAtProjectComponent = useString("")
  const [additionalJson, setAdditionalJson] = useState<any>([])
  const [inviteeBoms, setInviteeBoms] = useState<any>([])
  const versionHistory = useString("")
  const [historyDetail, setHistoryDetail] = useState<BuildHistoryDetail>(
    defaultBuildHistoryDetail
  )
  const [histories, setHistories] = useState<BuildHistoryDetail[]>([])
  const isDeleteFileBOM = useBoolean()
  const isUpdatingBom = useBoolean(false)
  const updatedAtBOM = useString("")

  const {
    conversationMessages,
    idConversationParam,
    idProjectBuildComponentParam,
    idProjectComponentHistoryParam,
    idProjectComponentParam,
    isReadComponent,
    nameBOM,
    projectBuild,
    projectDetail,
    setProjectDetail,
    setConversationMessages,
    setProjectBuild,
    idComponentHistory,
    conversationRole,
    setConversationRole,
  } = useInviteeComponent(params)

  const viewOnly =
    includes(
      [STATUS_BUILD.COMMITTED, STATUS_BUILD.MERGED, STATUS_BUILD.SAVE],
      historyDetail.status
    ) || conversationRole !== CONVERSATION_ROLE.INVITEE
  const isDeleted =
    projectBuild.status === STATUS_BUILD.DELETED ||
    componentDetail.status === ProjectComponentStatus.DeletedAttachment ||
    historyDetail?.build_status === BuildStatusNumber.DELETED_ATTACHMENT
  useEffect(() => {
    const isCheckId =
      !idProjectComponentParam ||
      !idProjectBuildComponentParam ||
      !idConversationParam
    if (isCheckId) {
      pushTo(PATH.conversations)
      return
    }
    getProjectComponentDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    idProjectComponentParam,
    idProjectBuildComponentParam,
    idConversationParam,
  ])

  const getProjectComponentDetail = async () => {
    isLoading.setValue(true)
    try {
      await getConversationMessages(idConversationParam)
      const dataComponentRes = await getProjectComponentDetailMiddleware(
        idProjectComponentParam,
        idConversationParam
      )
      setComponentDetail(dataComponentRes)
      await getProjectBuildComponent(idProjectBuildComponentParam)
      if (dataComponentRes.type.key !== TYPE_PROJECT_COMPONENT.BOM) {
        pushTo(PATH.conversations)
        return
      }
      await getConversationRole()
      await getHistories(dataComponentRes.project_id)

      updatedAtProjectComponent.setValue(dataComponentRes.updated_at)
      nameBOM.setValue(dataComponentRes.name.replace(" ", "_"))

      await getIsReadProjectBuildComponent(idProjectBuildComponentParam)

      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.conversations)
    }
  }
  const getConversationRole = async () => {
    const role = await getConversationRoleMiddleWare(
      idProjectBuildComponentParam,
      idConversationParam
    )
    setConversationRole(role.role)
  }
  const getComponentHistory = async (originIdHistory: string) => {
    const dataComponentHistoryRes = await getProjectComponentHistoryMiddleware(
      originIdHistory,
      idConversationParam
    )
    if (dataComponentHistoryRes.code) {
      versionHistory.setValue(dataComponentHistoryRes.code)
    }
    setHistoryDetail(dataComponentHistoryRes)
  }
  const handleChangeHistoryDetail = useCallback(
    (newHistoryDetail: BuildHistoryDetail) => () => {
      if (newHistoryDetail.id === historyDetail.id) {
        return
      }
      isLoading.setValue(true)
      isUpdatingBom.setValue(false)
      setHistoryDetail(newHistoryDetail)
      idComponentHistory.setValue(newHistoryDetail.id)
      getBOM(newHistoryDetail.id, idConversationParam)
    },
    [idComponentHistory.value, isLoading, historyDetail]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  )
  const getProjectBuildComponent = async (idProjectBuildComponent: string) => {
    const dataRes = await getBuildComponentDetailMiddleware(
      idProjectBuildComponent,
      idConversationParam
    )
    if (
      locationInviteeComponentBOM.state &&
      Boolean(locationInviteeComponentBOM?.state?.breadcrumpProject)
    ) {
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        dataRes.project_id,
        idConversationParam
      )
      setProjectDetail(dataProjectDetailRes)
    }

    setProjectBuild(dataRes)
  }
  const getIsReadProjectBuildComponent = async (
    oldIdProjectBuildComponent: string
  ) => {
    const dataRes = await getIsReadBuildComponentMiddleware(
      oldIdProjectBuildComponent,
      idConversationParam
    )
    isReadComponent.setValue(dataRes.is_read_invitee_comment)
  }
  const getHistories = async (
    projectId: string,
    loading = false,
    findItemCommit = true
  ) => {
    try {
      const dataRes = await getHistoriesBuildComponentConvoMiddleware(
        projectId,
        idProjectBuildComponentParam,
        idConversationParam
      )
      setHistories(dataRes)

      if (dataRes.length) {
        await getDataBOM(dataRes, findItemCommit)
      }
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }
  const getDataBOM = async (
    newDataRes: BuildHistoryDetail[],
    findItemCommit = true
  ) => {
    const { newHistoryDetail } = findHistoryTreeDefault(
      newDataRes,
      findItemCommit,
      locationInviteeComponentBOM
    )
    if (newHistoryDetail.code) {
      versionHistory.setValue(newHistoryDetail.code)
    }
    idComponentHistory.setValue(newHistoryDetail.id)
    setHistoryDetail(newHistoryDetail)
    idComponentHistory.setValue(newHistoryDetail.id)
    await getBOM(
      newHistoryDetail.id,
      idConversationParam,
      false,
      newHistoryDetail.decryptedShareKey
    )
  }
  const getBOM = useCallback(
    async (
      oldIdHistory: string,
      oldidConversation: string,
      loading = false,
      decryptedShareKey?: string
    ) => {
      try {
        const dataRes = await getProjectComponentConversationBOMMiddleware(
          oldIdHistory,
          oldidConversation,
          decryptedShareKey
        )
        setInfoFileBOM({
          name: dataRes.file_name,
          file: dataRes.file,
        })
        const newTables = isArray(dataRes.bom_json)
          ? dataRes.bom_json
          : Object.values(dataRes.bom_json)[0]
        setTableBOM(newTables)
        setAdditionalJson(dataRes.additional_json)
        setInviteeBoms(dataRes.invitee_boms)
        updatedAtBOM.setValue(dataRes.updated_at)
        isUpdatingBom.setValue(false)
        if (loading) {
          isLoading.setValue(false)
        }
      } catch (error) {
        if (loading) {
          isLoading.setValue(false)
        }
      }
    },
    [isLoading, updatedAtBOM]
  )

  const handleUpdateDataWhenChangeHistory = (getFirst = false) => {
    isLoading.setValue(true)
    getHistories(componentDetail.project_id, true, getFirst)
    // updatedAtComponentDetail(new Date().toISOString())
  }
  const getConversationMessages = async (
    conversationId: string,
    loading = false
  ) => {
    const dataRes = await getConversationMessagesMiddleware(conversationId)
    if (dataRes.is_conversation_un_active) {
      pushTo(PATH.notFound)
      return
    }
    setConversationMessages(dataRes)
    if (!dataRes.invitee.accepted && !dataRes.is_owner) {
      await acceptedSharingConversationMiddleware(conversationId)
    }
    if (loading) {
      isLoading.setValue(false)
    }
  }

  const handleUpdateFileBom = (newFile: FormData) => {
    if (!isTypeFile(Object(newFile.get("file")).name, "csv|xls|xlsx")) {
      toast(
        <LabelNotificationPage
          messenger={`${MESSENGER_NOTIFICATION.UPDATE_FILE_PCB_ERROR} csv,xls,xlsx`}
          type="warning"
        />
      )
      return
    }
    if (!idComponentHistory.value || !workerUploadFile) {
      return
    }
    isLoading.setValue(true)
    inviteeUploadFileBOMMiddleware(
      workerUploadFile,
      idComponentHistory.value,
      idConversationParam,
      newFile,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getBOM(
            idComponentHistory.value,
            idConversationParam,
            true,
            historyDetail.decryptedShareKey
          )
          updatedAtProjectComponent.setValue(new Date().toISOString())
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }

  const onDeleteFileBOM = () => {
    isDeleteFileBOM.setValue(true)
  }

  const onSubmitDeleteFileBOM = () => {
    if (!idComponentHistory.value) {
      return
    }
    inviteeDeleteFileBOMMiddleware(
      idComponentHistory.value,
      idConversationParam,
      infoFileBOM.name,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getBOM(
            idComponentHistory.value,
            idConversationParam,
            true,
            historyDetail.decryptedShareKey
          )
          updatedAtProjectComponent.setValue(new Date().toISOString())
          isDeleteFileBOM.setValue(false)
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }

  const updateDataWhenChangeColumn = async (callback: () => void) => {
    isLoading.setValue(true)
    await getBOM(
      idComponentHistory.value,
      idConversationParam,
      true,
      historyDetail.decryptedShareKey
    )
    updatedAtProjectComponent.setValue(new Date().toISOString())
    callback()
  }
  const renderInviteeBOMComponent = () => {
    if (isDeleted || !isArray(tableBOM) || !tableBOM.length) {
      return null
    }
    return (
      <InviteeBOMDetailCard
        updatedAtBOM={updatedAtBOM.value}
        BOMJson={tableBOM}
        nameBOM={nameBOM.value}
        conversationId={idConversationParam}
        projectComponentHistory={historyDetail}
        updateDataWhenChangeColumn={updateDataWhenChangeColumn}
        additionalJson={isArray(additionalJson) ? additionalJson : []}
        inviteeBoms={isArray(inviteeBoms) ? inviteeBoms : []}
        isInvitee={userInfo.id === conversationMessages.invitee.user_id}
        isCloseConversation={
          BUILD_INVITEE_DETAIL_STATUS[
            conversationMessages.invitee.in_production_status
          ] === BUILD_INVITEE_DETAIL_STATUS[3] ||
          projectBuild.status === STATUS_BUILD.CANCELLED ||
          Boolean(projectBuild.is_archived)
        }
        isDeleted={isDeleted}
        conversationRole={conversationRole}
        isAddColumn={isUpdatingBom}
        componentType={ComponentType.BOM}
      />
    )
  }

  const renderLayout = () => {
    if (!historyDetail.id) {
      return null
    }
    if (!historyDetail.decryptedShareKey) {
      return <EncryptedDataWarning type="component" />
    }
    return (
      <SectionCustom>
        {/* {histories.length !== 0 && (
          <HistoryTreeCard
            histories={histories}
            historyDetail={historyDetail}
            handleChangeHistoryDetail={handleChangeHistoryDetail}
            typeComponent={componentDetail.type.key}
            titlePage={"build-component"}
          />
        )} */}
        <div className="flex items-center md:grid md:grid-cols-4 min-h-[128px] md:min-h[110px] overflow-x-auto overflow-y-hidden mr-6 mt-1 gap-8">
          <FileInviteePCBItem
            title={STATUS_TYPE_FILE_BUILD.BOM}
            file={infoFileBOM.file}
            fileName={infoFileBOM.name}
            isDeleted={isDeleted}
            conversationId={idConversationParam}
            acceptFile=".csv,.xls,.xlsx"
            //ver
            isAction={viewOnly}
            onRemoveFile={onDeleteFileBOM}
            handleUpdateFile={handleUpdateFileBom}
            containerFiles={`container-files-${STATUS_TYPE_FILE_BUILD.BOM}`}
            decryptedShareKey={historyDetail.decryptedShareKey}
            // versionId={historyDetail.id}
          />
        </div>
        {renderInviteeBOMComponent()}
      </SectionCustom>
    )
  }
  return (
    <PageInviteeComponent
      componentDetail={componentDetail}
      updatedAtProjectComponent={updatedAtProjectComponent.value}
      projectBuild={projectBuild}
      idConversationParam={idConversationParam}
      idProjectBuildComponentParam={idProjectBuildComponentParam}
      conversationMessages={conversationMessages}
      projectDetail={projectDetail}
      idProjectComponentHistoryParam={historyDetail.id}
      nameBOM={nameBOM.value}
      userInfo={userInfo}
      isReadComponent={isReadComponent}
      histories={histories}
      versionHistory={historyDetail}
      isLoading={isLoading.value}
      isDeleted={isDeleted}
      handleUpdateDataWhenChangeHistory={handleUpdateDataWhenChangeHistory}
      conversationRole={conversationRole}
      isUpdatingBom={isUpdatingBom.value}
      commitButtonStage={{
        isActive:
          infoFileBOM.file &&
          infoFileBOM.name &&
          historyDetail.status === STATUS_BUILD.DRAFT
            ? true
            : false,
        tooltipHelper: "Please upload BOM",
      }}
      setHistoryDetail={setHistoryDetail}
    >
      {renderLayout()}
      {isDeleteFileBOM.value && (
        <ModalDelete
          onCloseModal={() => isDeleteFileBOM.setValue(false)}
          title={`Are you sure to delete file BOM?`}
          label={`Confirmation`}
          onSubmit={onSubmitDeleteFileBOM}
          titleButton="Delete"
        />
      )}
    </PageInviteeComponent>
  )
}

export default InviteeBOMComponent
