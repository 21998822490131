import ModalCustom from "components/ModalCustom"
import { useBoolean, useWindowSize } from "helpers/hooks"
import { useContext, useEffect, useState } from "react"
import {
  getProjectBuildListInviteeByBuildComponentMiddleware,
  putProjectBuildToInProductionMiddleware,
} from "../api.services"
import FormLabelWarning from "components/FormLabel/FormLabelWarning"
import { ProjectComponentDetail } from "pages/project-component/types"
import FromSelectInviteeItem from "./FromSelectInviteeItem"
import { STATUS_RESPONSE } from "types"
import { pushTo } from "helpers/history"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { PATH } from "constants/path"
import { ProjectComponentBuildDetail } from "../project-build.type"
import Button from "components/Button/Button"
import {
  BuildStatusNumber,
  ProjectComponentStatus,
  STATUS_BUILD,
} from "components/Status/types"
import FormInputCard from "pages/auth/molecules/FormInputCard"
import { SelectedDefaultProp } from "components/Select/types"
import { isEmpty } from "lodash"
import { BuildOverviewPageContext } from "pages/build-overview/contexts/BuildOverviewPage.context"

interface Props {
  onCloseModal: () => void
  openModal: boolean
  components: ProjectComponentDetail[]
  productBuild: ProjectComponentBuildDetail
  listStatus: SelectedDefaultProp[]
  onUpdateDataEditNameBuild: (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => void
}
export interface SelectUserBuild {
  version: string
  code: string
  typeCode: string
  projectBuildComponentId: string
  invitees: {
    id: string
    name: string
    email: string
  }[]
}
const FormInProduction = (props: Props) => {
  const {
    onCloseModal,
    openModal,
    components,
    productBuild,
    listStatus,
    onUpdateDataEditNameBuild,
  } = props
  const [dataSelects, setDataSelect] = useState<SelectUserBuild[]>([])
  const isLoading = useBoolean()
  const [objSelect, setObjSelect] = useState({})
  const showError = useBoolean(false)
  const [valueSelectStatus, setValueSelectStatus] =
    useState<SelectedDefaultProp>({
      label: "",
      value: "",
    })
  const { setBuild } = useContext(BuildOverviewPageContext)
  const { isMobile } = useWindowSize()
  useEffect(() => {
    if (!openModal) {
      return
    }
    if (!isEmpty(listStatus)) {
      setValueSelectStatus(listStatus[0])
    }
    getUserInviteeInBuildComponent(components)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(components), openModal])

  useEffect(() => {
    if (!showError.value) {
      return
    }
    if (!handleDisableButton()) {
      showError.setValue(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objSelect, showError])
  const getUserInviteeInBuildComponent = async (
    oldComponents: ProjectComponentDetail[]
  ) => {
    isLoading.setValue(true)
    const calls: Promise<any>[] = []
    const newObjSelect = {}
    oldComponents.forEach(async (component) => {
      if (component.status === ProjectComponentStatus.DeletedAttachment) {
        return
      }
      const promise = new Promise<SelectUserBuild>(async (resolve) => {
        const res = await getProjectBuildListInviteeByBuildComponentMiddleware(
          component.project_build_component_id ?? ""
        )
        newObjSelect[component.project_build_component_id ?? ""] = ""
        resolve({
          code: component.code,
          typeCode: component.type.key,
          projectBuildComponentId: component.project_build_component_id ?? "",
          invitees: res,
          version: component.version,
        })
      })
      calls.push(promise)
    })
    const data = await Promise.all(calls)

    setObjSelect(newObjSelect)
    setDataSelect(data)
    isLoading.setValue(false)
  }
  const handleDisableButton = () => {
    let isDisable = false
    Object.values(objSelect).forEach((el) => {
      if (!el) {
        isDisable = true
      }
    })
    return isDisable
  }

  const onSubmit = () => {
    if (handleDisableButton()) {
      showError.setValue(true)
      return
    }
    if (!productBuild.id) {
      return
    }
    const dataRequest: {
      project_build_component_id: string
      invitee_id: string
    }[] = []

    Object.keys(objSelect).forEach((el) => {
      if (el) {
        const newValue = objSelect[el]
        if (newValue) {
          const newValueSplit = newValue.split("_")
          if (newValueSplit.length) {
            dataRequest.push({
              invitee_id: newValueSplit[0],
              project_build_component_id: el,
            })
          }
        }
      }
    })
    isLoading.setValue(true)

    putProjectBuildToInProductionMiddleware(
      productBuild.id,
      {
        data: dataRequest,
        status: valueSelectStatus.value,
      },
      dataSelects,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        isLoading.setValue(false)
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          setObjSelect({})
          setDataSelect([])
          onCloseModal()
          setBuild(dataRes.id, { status: dataRes.status })
          onUpdateDataEditNameBuild({
            status: dataRes.status,
            updated_at: dataRes.updated_at,
            proceed_status: dataRes.proceed_status,
          })
          pushTo(PATH.buildOverview, {
            status: dataRes.status,
            idProject: dataRes.project_id,
            idProjectBuild: dataRes.id,
          })
        }
      },
      productBuild.code,
      Number(valueSelectStatus.value) === BuildStatusNumber.IN_PRODUCTION
        ? "in production"
        : "in progress"
    )
  }
  return (
    <ModalCustom
      label="Going into production"
      widthModal={isMobile ? 340 : 640}
      handleChangeButton={() => onCloseModal}
      bodyChildren={
        <div className="flex flex-col">
          <div className="pt-1 pb-3">
            <FormInputCard
              title="Select build status*"
              fontWeightText="font-semibold"
            >
              <div className="flex">
                {listStatus.map((item, key) => (
                  <Button
                    key={key}
                    sizeBtn="small"
                    title={item.label}
                    colorBtn="white"
                    onClick={() => setValueSelectStatus(item)}
                    styleButton={
                      valueSelectStatus.value === item.value
                        ? {
                            border: "1px solid rgb(86, 160, 18)",
                            boxShadow: "rgb(86 160 18 / 10%) 0px 0px 0px 4px",
                            height: 32,
                            width: 110,
                            marginRight: 10,
                          }
                        : {
                            height: 32,
                            width: 110,
                            backgroundColor: "#F7F7F7",
                            marginRight: 10,
                          }
                    }
                  />
                ))}
              </div>
            </FormInputCard>
          </div>
          <div className="pt-1 pb-3">
            <FormLabelWarning
              label="Choose invitee to work with and components related to them."
              minHeight={44}
              hiddenIcon
            />
          </div>
          <div className="max-h-[450px] md:max-h-auto overflow-auto">
            {dataSelects.length
              ? dataSelects.map((data, index) => (
                  <FromSelectInviteeItem
                    dataSelect={data}
                    key={index}
                    objSelect={objSelect}
                    setObjSelect={setObjSelect}
                  />
                ))
              : null}
          </div>

          {showError.value ? (
            <div className="flex">
              <p
                className="font-normal"
                style={{
                  fontSize: 13,
                  lineHeight: "22px",
                  color: "#EA4545",
                }}
              >
                Please completed vendor selection to proceed further.
              </p>
            </div>
          ) : null}
          <div className="flex flex-col md:flex-row w-full items-center gap-3 md:gap-0 md:justify-end mt-8">
            <Button
              title="Cancel"
              widthBtn={isMobile ? 280 : 160}
              colorBtn="white"
              onClick={onCloseModal}
            />
            <Button
              title="Proceed"
              widthBtn={isMobile ? 280 : 160}
              onClick={onSubmit}
              className="md:ml-3"
            />
          </div>
        </div>
      }
    />
  )
}
export default FormInProduction
