import { encryptionHelper } from "helpers/encryption"
import _, { isEmpty, isUndefined, mapValues } from "lodash"
import { saveAs } from "file-saver"
import { Stores, updateData } from "services/indexedDB"
import forge from "node-forge"
import Axios from "axios"

export enum EncryptionKeys {
  userMasterKey = "userMasterKey",
  userVaultKey = "userVaultKey",
  projectEncryptionKeys = "projectEncryptionKeys",
  currentProjectEncryptionKey = "currentProjectEncryptionKey",
  conversationEncryptionKeys = "conversationEncryptionKeys",
  currentConversationEncryptionKey = "currentConversationEncryptionKey",
  conversationNoteEncryptionKey = "conversationNoteEncryptionKey",
  buildAdditionalInfoEncryptionKeys = "buildAdditionalInfoEncryptionKeys",
}

export interface ProjectSyncKeys {
  projectId: string
  projectKey: string
  conversations: {
    conversationId: string
    conversationKey: string
  }[]
  buildAdditionalInfos: {
    buildAdditionalInfoId: string
    buildAdditionalInfoKey: string
  }[]
}
export type UpdateProjectSensitiveDataRequest = {
  context_data: ProjectSensitiveData
  keys: ProjectSyncKeys
}

export type ProjectSensitiveData = {
  versions: {
    id: string
    code: string
    commit: string
  }[]
  boms: {
    id: string
    additional_json: string
    mouser_data: string
  }[]
  firmwares: {
    id: string
    description: string
  }[]
  softwares: {
    id: string
    description: string
  }[]
  mechanicals: {
    id: string
    description: string
  }[]
  miscellaneous: {
    id: string
    description: string
  }[]
  build_additional_infos: {
    id: string
    description: string
    project_build_id: string
  }[]
  build_extra_infos: {
    id: string
    description: string
  }[]
  logs: {
    id: string
    content: string
  }[]
  project_comments: {
    id: string
    content: string
  }[]
  conversation_messages: {
    id: string
    conversation_id: string
    content: string
  }[]
  conversation_ids: string[]
  build_ids: string[]
}

export const encryptionController = () => {
  let encryptionKey = {
    ...mapValues(EncryptionKeys, (value) => {
      return localStorage.getItem(value) || ""
    }),
  }
  const userEmail = localStorage.getItem("user_email") || ""

  const storeEncryptionKey = (key: EncryptionKeys, value: string) => {
    localStorage.setItem(key, value)
    encryptionKey[key] = value
  }

  const storeVaultKey = (encryptedVaultKey: string) => {
    const vaultKey = encryptionHelper.decrypt(
      encryptionKey.userMasterKey,
      encryptedVaultKey
    )
    storeEncryptionKey(EncryptionKeys.userVaultKey, vaultKey as string)
  }

  const generateMasterKeyAndPasswordHashed = async (
    password: string,
    email: string
  ) => {
    const masterKey = encryptionHelper.getPBKDFHash(password, email, 100000)
    const passwordHash = encryptionHelper.getPBKDFHash(masterKey, password)
    return {
      masterKey,
      passwordHash,
    }
  }

  const generatePasswordHashed = async (password: string) => {
    return encryptionHelper.getPBKDFHash(encryptionKey.userMasterKey, password)
  }

  const generateVaultKeyAndBackUpKeyAndEncryptedVersion = async (
    masterKey: string
  ) => {
    const generatedBackupKey = await Axios.get("/api/auth/backup-key")

    const { backupKey, encryptedBackupKey } = generatedBackupKey.data.data

    const vaultKey = encryptionHelper.createRandomKey()
    const encryptedVaultkey = encryptionHelper.encrypt(
      masterKey,
      vaultKey
    ) as string

    const encryptedBackUpkey = encryptionHelper.encrypt(
      backupKey,
      vaultKey
    ) as string

    return {
      vaultKey,
      backupKey,
      encryptedBackUpkey,
      encryptedVaultkey,
      encryptedKMSBackupKey: encryptedBackupKey,
    }
  }

  const decryptVaultKeyAndStore = (
    encryptedVaultKey: string,
    masterKey: string
  ) => {
    if (!encryptedVaultKey) {
      return
    }
    const vaultKey = encryptionHelper.decrypt(
      masterKey,
      encryptedVaultKey
    ) as string
    storeEncryptionKey(EncryptionKeys.userMasterKey, masterKey)
    storeEncryptionKey(EncryptionKeys.userVaultKey, vaultKey)
  }

  const decryptPrivateKeyAndStore = async (
    encryptedPrivateKey: string,
    userId: string,
    masterKey: string,
    buildKeys: {
      id: string
      encryptedKey: string
      additionalId: string
    }[],
    conversationKeys: {
      id: string
      encryptedKey: string
      additionalId: string
    }[]
  ) => {
    if (!encryptedPrivateKey || !userId) {
      return
    }
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string

    let privateKey = encryptionHelper.decrypt(
      userVaultKey,
      encryptedPrivateKey
    ) as string
    if (privateKey === encryptedPrivateKey) {
      privateKey = encryptionHelper.decrypt(
        masterKey,
        encryptedPrivateKey
      ) as string
    }
    if (privateKey) {
      const privatePemKey = forge.pki.privateKeyFromPem(privateKey)
      const projectKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
      )
      if (!isUndefined(buildKeys) && buildKeys.length) {
        const buildKeyLocal: Record<string, string> = {}
        buildKeys.forEach((buildKey) => {
          if (buildKey.additionalId) {
            const projectKey = projectKeys[buildKey.additionalId]
            if (projectKey) {
              buildKeyLocal[buildKey.id] = encryptionHelper.decrypt(
                projectKey,
                buildKey.encryptedKey
              ) as string
            }
          } else {
            try {
              const decryptedKey = privatePemKey.decrypt(
                buildKey.encryptedKey,
                "RSA-OAEP"
              )
              buildKeyLocal[buildKey.id] = decryptedKey
            } catch (error) {
              buildKeyLocal[buildKey.id] = buildKey.encryptedKey
            }
          }
        })
        localStorage.setItem(
          EncryptionKeys.buildAdditionalInfoEncryptionKeys,
          JSON.stringify(buildKeyLocal)
        )
      }

      if (!isUndefined(conversationKeys) && conversationKeys.length) {
        const conversationKeyLocal: Record<string, string> = {}
        conversationKeys.forEach((conversationKey) => {
          if (conversationKey.additionalId) {
            const projectKey = projectKeys[conversationKey.additionalId]
            if (projectKey) {
              conversationKeyLocal[conversationKey.id] =
                encryptionHelper.decrypt(
                  projectKey,
                  conversationKey.encryptedKey
                ) as string
            }
          } else {
            try {
              const decryptedKey = privatePemKey.decrypt(
                conversationKey.encryptedKey,
                "RSA-OAEP"
              )
              conversationKeyLocal[conversationKey.id] = decryptedKey
            } catch (error) {
              conversationKeyLocal[conversationKey.id] =
                conversationKey.encryptedKey
            }
          }
        })
        localStorage.setItem(
          EncryptionKeys.conversationEncryptionKeys,
          JSON.stringify(conversationKeyLocal)
        )
      }
      await updateData(Stores.PrivateKeys, {
        privateKey,
        id: userId,
      })
    }
  }

  const downLoadBackUpKey = (backupKey: string, user_email: string) => {
    const file = new Blob(
      [
        `RECOVERY CODE \nThis code is used to reset your Tracelium password.\nSave your recovery code somewhere safe that you can access.\n\n${backupKey}\n\n(${user_email})`,
      ],
      { type: "text/plain;charset=utf-8" }
    )
    saveAs(file, `recovery-code-${user_email}.txt`)
  }

  const encryptLog = (message: string, encryptionKey?: string) => {
    const currentProjectEncryptionKey =
      encryptionKey ||
      (localStorage.getItem(
        EncryptionKeys.currentProjectEncryptionKey
      ) as string)
    if (_.isEmpty(currentProjectEncryptionKey)) return message
    return encryptionHelper.encrypt(
      currentProjectEncryptionKey,
      message
    ) as string
  }

  const decryptUnknowMessage = (
    message: string,
    conversationId?: string,
    projectId?: string
  ) => {
    try {
      const conversationEncryptionKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.conversationEncryptionKeys) || "{}"
      )
      const projectEncryptionKeys = JSON.parse(
        localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
      )
      if (!conversationId && !projectId) return message
      let result = encryptionHelper.decrypt(
        conversationEncryptionKeys[conversationId || ""],
        message
      ) as string
      if (result === message || result === "") {
        result = encryptionHelper.decrypt(
          projectEncryptionKeys[projectId || ""],
          message
        ) as string
      }
      return result
    } catch (error) {
      return message
    }
  }
  const encryptUnknowFile = async (file: any, encryptionKey?: string) => {
    const readFile = await encryptionHelper.readFile(file)
    let fileArrayBuffer = new Uint8Array(readFile as any)
    const temp = encryptionHelper.toWordArray(fileArrayBuffer)
    if (encryptionKey) {
      return encryptionHelper.encrypt(encryptionKey, temp) as string
    }
    const currentProjectEncryptionKey =
      encryptionKey ||
      (localStorage.getItem(
        EncryptionKeys.currentProjectEncryptionKey
      ) as string)
    if (
      !currentProjectEncryptionKey ||
      _.isEmpty(currentProjectEncryptionKey)
    ) {
      return file
    }
    return encryptionHelper.encrypt(currentProjectEncryptionKey, temp) as string
  }
  const getEncryptionKey = (options: {
    type:
      | "conversation"
      | "conversation_note"
      | "project"
      | "build_additional_info"
      | "component-shared"
    dataType: "string" | "file"
    encryptionKey?: string
    relationId?: string
  }) => {
    let key = options.encryptionKey
    if (isEmpty(key)) {
      switch (options.type) {
        case "project":
          key = localStorage.getItem(
            EncryptionKeys.currentProjectEncryptionKey
          ) as string
          if (options.relationId) {
            key = JSON.parse(
              localStorage.getItem(
                EncryptionKeys.projectEncryptionKeys
              ) as string
            )[options.relationId]
          }
          break
        case "conversation":
          key = localStorage.getItem(
            EncryptionKeys.currentConversationEncryptionKey
          ) as string
          if (options.relationId) {
            key = JSON.parse(
              localStorage.getItem(
                EncryptionKeys.conversationEncryptionKeys
              ) as string
            )[options.relationId]
          }
          break

        case "conversation_note":
          key = localStorage.getItem(EncryptionKeys.userVaultKey) as string
          break
        case "build_additional_info":
          key = ""
          if (options.relationId) {
            key = JSON.parse(
              localStorage.getItem(
                EncryptionKeys.buildAdditionalInfoEncryptionKeys
              ) as string
            )?.[options.relationId]
          }
          break
        default:
          key = ""
          break
      }
    }
    return key
  }
  const encrypt = async (
    data: any,
    options: {
      type:
        | "conversation"
        | "conversation_note"
        | "project"
        | "build_additional_info"
        | "component-shared"
      dataType: "string" | "file"
      encryptionKey?: string
      relationId?: string
    }
  ) => {
    //get key
    const key = getEncryptionKey(options)
    if (!key || isEmpty(key)) {
      return data
    }
    if (options.dataType === "string") {
      return encryptionHelper.encrypt(key, data) as string
    }
    const readFile = await encryptionHelper.readFile(data)
    let fileArrayBuffer = new Uint8Array(readFile as any)
    const temp = encryptionHelper.toWordArray(fileArrayBuffer)
    return encryptionHelper.encrypt(key, temp) as string
  }

  const decrypt = async (
    data: any,
    options: {
      type:
        | "conversation"
        | "conversation_note"
        | "project"
        | "build_additional_info"
        | "component-shared"
      dataType: "string" | "file"
      encryptionKey?: string
      relationId?: string
    }
  ) => {
    //get key
    const key = getEncryptionKey(options)
    if (!key || isEmpty(key)) {
      return data
    }
    console.log("Decrypt key", key)
    return encryptionHelper.decrypt(
      key,
      data,
      options.dataType === "file"
    ) as string
  }

  const setCurrentProjectKey = (projectId: string) => {
    const projectKeys = JSON.parse(
      localStorage.getItem(EncryptionKeys.projectEncryptionKeys) || "{}"
    )
    localStorage.setItem(
      EncryptionKeys.currentProjectEncryptionKey,
      projectKeys[projectId] || ""
    )
  }
  const decryptProjectKey = (encryptedKey: string) => {
    const userVaultKey = localStorage.getItem(
      EncryptionKeys.userVaultKey
    ) as string
    return encryptionHelper.decrypt(userVaultKey, encryptedKey)
  }

  const isValidKeyEncryption = (key: string) => {
    if (isEmpty(key)) {
      return false
    }
    if (key === "null" || key === "undefined") {
      return false
    }
    return true
  }

  return {
    EncryptionKeys,
    storeVaultKey,
    storeEncryptionKey,
    generateMasterKeyAndPasswordHashed,
    generateVaultKeyAndBackUpKeyAndEncryptedVersion,
    decryptVaultKeyAndStore,
    downLoadBackUpKey,
    ...encryptionKey,
    userEmail,
    generatePasswordHashed,
    encryptLog,
    encryptUnknowFile,
    setCurrentProjectKey,
    decryptUnknowMessage,
    decryptProjectKey,
    decryptPrivateKeyAndStore,
    encrypt,
    decrypt,
    isValidKeyEncryption,
  }
}
