import { useContext, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { pushTo } from "helpers/history"
import { PATH } from "constants/path"
import PageLayout from "pages/layout/PageLayout"
import {
  ProjectDetail,
  defaultProjectDetail,
  STATUS_PROJECT_ROLE,
} from "pages/projects/types"
import {
  getProjectDetailMiddleware,
  getProjectRoleMiddleware,
} from "pages/projects/services/api"
import {
  IUseDefaultValueProps,
  useBoolean,
  useNumber,
  useString,
  useWindowSize,
} from "helpers/hooks"
import {
  ProjectComponentDetail,
  CreateBuildRequest,
  Folder,
} from "../../project-component/types"
import {
  getBuildStatusMiddleware,
  getProjectComponentsMiddleware,
} from "../../project-component/services/api"
import { cloneDeep, includes, isUndefined, reduce } from "lodash"
import LabelNotificationPage from "components/Notification/LabelNotificationPage"
import { toast } from "react-toastify"
import { STATUS_RESPONSE } from "types"
import Header from "../../project-build/organisms/ProjectBuildHeader"
import InfoDraftBuildCard from "../../project-build/organisms/InfoDraftBuildCard"
import { SelectedDefaultProp } from "components/Select/types"
import { ProjectComponentStatus, STATUS_BUILD } from "components/Status/types"
import { getComponentTypesMiddleware } from "pages/project-component-detail/services"
import ListTypeBuildCard from "../../project-build/organisms/ListTypeBuildCard"
import ComponentDetailInBuild from "../../project-build/organisms/ComponentDetailInBuild "
import FooterSelectComponentCard from "../../project-build/organisms/FooterSelectComponentCard"
import {
  emptyProjectBuildDetail,
  MENU_TAB_BUILD,
  ProjectComponentBuildDetail,
} from "../../project-build/project-build.type"
import ComponentBuildCard from "../../project-build/organisms/ComponentBuildCard"
import {
  deleteComponentInBuildMiddleware,
  postAddBuildMiddleware,
  putProjectBuildMiddleware,
} from "../../project-build/api.services"
import { PermissionProjectBuildPage } from "../../project-build/contexts/PermissionProjectBuildPage.context"
import { checkPermissionPage, umamiTracking } from "helpers/utils"
import { EVENT } from "constants/events"
import { onRedirectProjectBuild } from "helpers/redirect"
import { ReactComponent as ArrowLeftIcon } from "assets/images/icons/arrow-left.svg"
import { BuildOverviewPageContext } from "../contexts/BuildOverviewPage.context"
import { useShareInvitation } from "hooks/useShareInvitation"
import { EncryptedDataWarning } from "components/EncryptedDataWarning"
import { getWorkerLocalStorageData } from "workers/utils"
import { encryptionHelper } from "helpers/encryption"
interface Props {
  projectBuild: ProjectComponentBuildDetail
  isArchived: boolean | undefined
  showBuildDetailLayout: IUseDefaultValueProps
  onUpdateDataEditNameBuild: (
    updatedData: Partial<ProjectComponentBuildDetail>
  ) => void
  updatedAtBuild: string
}
const BuildDraft = (props: Props) => {
  const params = useParams<{
    idProject: string
    idProjectBuild: string
    status: string
  }>()
  const {
    showBuildDetailLayout,
    isArchived,
    projectBuild,
    onUpdateDataEditNameBuild,
    updatedAtBuild,
  } = props
  const idProjectParams = params?.idProject || ""
  const idProjectBuildParams = params?.idProjectBuild || ""
  const idStatusBuildParams = params?.status || ""

  const [projectDetail, setProjectDetail] =
    useState<ProjectDetail>(defaultProjectDetail)
  const isLoading = useBoolean()
  const [listComponents, setListComponent] = useState<ProjectComponentDetail[]>(
    []
  )
  const [listFolders, setListFolder] = useState<Folder[]>([])
  const [originListComponents, setOriginListComponent] = useState<
    ProjectComponentDetail[]
  >([])
  const [cpnTypeOptions, setCpnTypeOptions] = useState<SelectedDefaultProp[]>(
    []
  )
  const [folderSelected, setFolderSelected] = useState<SelectedDefaultProp[]>(
    []
  )
  const [cpnTypeSelected, setCpnTypeSelected] = useState<SelectedDefaultProp[]>(
    []
  )
  const selectAll = useBoolean(false)
  const availableComponents = useNumber(0)
  const [objActive, setObjActive] = useState({})
  // const [projectBuild, setProjectBuild] = useState<ProjectComponentBuildDetail>(
  //   emptyProjectBuildDetail
  // )
  const [componentActiveIds, setComponentActiveIds] = useState<string[]>([])
  const archiveProject = useBoolean(true)
  const archiveBuild = useBoolean(false)

  const [listStatus, setListStatus] = useState<SelectedDefaultProp[]>([])
  const sizeWindow = useWindowSize()
  const viewOnlyShare = useBoolean(true)
  const { setBuild } = useContext(BuildOverviewPageContext)
  const { acceptedSharingProject } = useShareInvitation()
  const { projectEncryptionKeys, userId } = getWorkerLocalStorageData()

  useEffect(() => {
    if (!idProjectParams) {
      pushTo(PATH.projects)
      return
    }
    getProjectDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProjectParams, idProjectBuildParams, idStatusBuildParams])

  const getProjectDetail = async () => {
    isLoading.setValue(true)
    try {
      const dataProjectRoleRes = await getProjectRoleMiddleware(idProjectParams)
      if (!dataProjectRoleRes) {
        return
      }
      const dataProjectDetailRes = await getProjectDetailMiddleware(
        idProjectParams
      )
      await acceptedSharingProject(dataProjectDetailRes)

      archiveProject.setValue(Boolean(dataProjectDetailRes.is_archived))
      const dataCpnTypeRes = await getComponentTypesMiddleware()
      setProjectDetail(dataProjectDetailRes)
      viewOnlyShare.setValue(
        dataProjectRoleRes.role === STATUS_PROJECT_ROLE.VIEWER
      )

      if (idProjectBuildParams && idProjectBuildParams !== "new-draft") {
        await getProjectBuild(idProjectBuildParams)
      } else {
        await getListComponent(idProjectParams)
      }
      setCpnTypeOptions([
        ...dataCpnTypeRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])

      isLoading.setValue(false)
    } catch (error) {
      isLoading.setValue(false)
      pushTo(PATH.projects)
    }
  }

  const getProjectBuild = async (idBuild: string, loading = false) => {
    try {
      // const dataRes = await getProjectBuildMiddleware(idBuild)

      if (projectBuild.status !== STATUS_BUILD.DRAFT) {
        onRedirectProjectBuild(
          projectBuild.project_id,
          projectBuild.id,
          projectBuild.status
        )
      }
      const dataBuildStatusRes = await getBuildStatusMiddleware()
      setListStatus([
        ...dataBuildStatusRes.map((i) => ({
          label: i.key,
          value: String(i.value),
        })),
      ])
      setBuild(idBuild, {
        components: [
          ...new Set(JSON.parse(projectBuild.component_types) as string[]),
        ],
      })
      archiveBuild.setValue(Boolean(projectBuild.is_archived))
      await getListComponent(
        idProjectParams,
        false,
        projectBuild.components.map((el) => el.id)
      )

      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
      pushTo(PATH.projectComponent, {
        idProject: idProjectParams,
      })
    }
  }
  const getListComponent = async (
    idProject: string,
    loading = false,
    listIdComponentActive: string[] = []
  ) => {
    try {
      const dataComponentRes = await getProjectComponentsMiddleware(idProject)
      setListFolder(dataComponentRes.groups)
      setListComponent(dataComponentRes.components)
      onFilterComponent(
        dataComponentRes.groups,
        dataComponentRes.components,
        cpnTypeSelected,
        folderSelected,
        listIdComponentActive
      )
      setComponentActiveIds(listIdComponentActive)
      selectAll.setValue(false)
      if (loading) {
        isLoading.setValue(false)
      }
    } catch (error) {
      if (loading) {
        isLoading.setValue(false)
      }
    }
  }

  const onSubmitAddSelected = () => {
    const dataRequest: CreateBuildRequest = {
      project_id: projectDetail?.id,
      project_component_ids: Object.values(objActive),
    }
    isLoading.setValue(true)
    if (projectBuild.id) {
      putProjectBuildMiddleware(
        projectBuild.id,
        {
          project_component_ids: dataRequest.project_component_ids,
        },
        (
          type: STATUS_RESPONSE,
          messenger: string,
          dataRes?: ProjectComponentBuildDetail
        ) => {
          toast(<LabelNotificationPage messenger={messenger} type={type} />)
          if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
            getProjectBuild(dataRes.id, true)

            setObjActive({})
          } else {
            isLoading.setValue(false)
          }
        }
      )
      return
    }
    postAddBuildMiddleware(
      dataRequest,
      (
        type: STATUS_RESPONSE,
        messenger: string,
        dataRes?: ProjectComponentBuildDetail
      ) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS && dataRes) {
          getProjectBuild(dataRes.id, true)
          setObjActive({})
          pushTo(PATH.draftBuild, {
            idProject: idProjectParams,
            idProjectBuild: dataRes.id,
          })
        } else {
          isLoading.setValue(false)
        }
      }
    )
    umamiTracking(EVENT.BUILD.CREATE)
  }

  const onChangeSelectedAll = (oldSelected: boolean) => () => {
    selectAll.setValue(!oldSelected)
    let newObjActive = {}
    const newListComponents = cloneDeep(originListComponents)
    if (!oldSelected) {
      newObjActive = reduce(
        newListComponents,
        (newObj, component) => {
          if (component.is_valid_to_build) {
            newObj[component.id] = component.id
          }
          return newObj
        },
        {}
      )
    }
    setObjActive(newObjActive)
  }
  const onChangeTypeSelected = (newSelect: SelectedDefaultProp[]) => {
    onFilterComponent(
      listFolders,
      listComponents,
      newSelect,
      folderSelected,
      componentActiveIds
    )
    setCpnTypeSelected(newSelect)
  }
  const onChangeFolderSelected = (newSelect: SelectedDefaultProp[]) => {
    onFilterComponent(
      listFolders,
      listComponents,
      cpnTypeSelected,
      newSelect,
      componentActiveIds
    )
    setFolderSelected(newSelect)
  }
  const onFilterComponent = (
    folders: Folder[],
    components: ProjectComponentDetail[],
    selectedType: SelectedDefaultProp[],
    selectedFolder: SelectedDefaultProp[],
    listIdComponentActive: string[]
  ) => {
    let filterComponents: ProjectComponentDetail[] = []
    const convertFolderSelected = selectedFolder.map((item) => item.value)
    const convertSelected = selectedType.map((item) => Number(item.value))
    const filteredFolders =
      selectedFolder.length === 0
        ? folders
        : folders.filter((folder) => convertFolderSelected.includes(folder.id))
    filteredFolders.forEach((folder) => {
      filterComponents = [...filterComponents, ...folder.components]
    })
    if (selectedFolder.length === 0) {
      filterComponents = [...filterComponents, ...components]
    }
    const newDataComponent = filterComponents.filter(
      (el) => !includes(listIdComponentActive, el.id)
    )
    const isValidComponents = newDataComponent.filter((data) => {
      return (
        data.is_valid_to_build && data.status === ProjectComponentStatus.Active
      )
    })
    filterComponents =
      convertSelected.length === 0
        ? isValidComponents
        : isValidComponents.filter((el) =>
            convertSelected.includes(Number(el.type.value))
          )
    setOriginListComponent(filterComponents)
    availableComponents.setValue(filterComponents.length)
  }
  const onSelectComponent =
    (newIdComponent: string, isValidToBuild: boolean) => () => {
      if (
        !isValidToBuild ||
        checkPermissionPage({
          project: archiveProject.value,
          build: archiveBuild.value,
        })
      ) {
        return
      }
      const newObj = cloneDeep(objActive)
      if (newObj[newIdComponent]) {
        delete newObj[newIdComponent]
      } else {
        newObj[newIdComponent] = newIdComponent
      }
      setObjActive(newObj)
      selectAll.setValue(
        Object.values(newObj).length === availableComponents.value
      )
    }
  const onRemoveComponent = (idProjectBuildComponent: string) => (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (
      !idProjectBuildComponent ||
      checkPermissionPage({
        project: archiveProject.value,
        build: archiveBuild.value,
      })
    ) {
      return
    }
    isLoading.setValue(true)
    deleteComponentInBuildMiddleware(
      idProjectBuildComponent,
      (type: STATUS_RESPONSE, messenger: string) => {
        toast(<LabelNotificationPage messenger={messenger} type={type} />)
        if (type === STATUS_RESPONSE.SUCCESS) {
          getProjectBuild(projectBuild.id, true)
        } else {
          isLoading.setValue(false)
        }
      }
    )
  }
  const isDisabledButtonAdd = Object.values(objActive).length ? false : true

  const renderLayout = () => {
    if (!projectBuild.id || projectBuild.id !== idProjectBuildParams) {
      return null
    }
    const projectKey = projectEncryptionKeys[projectBuild.project_id]
    if (!projectKey) {
      return (
        <EncryptedDataWarning
          type="project"
          isOwner={projectDetail.created_by === userId}
          styleRoot={{
            background: "transparent",
            left: 13,
          }}
        />
      )
    }
    const decryptedBuildKey = encryptionHelper.decrypt(
      projectKey,
      projectBuild?.buildKey?.encryptedKey
    )
    if (
      !decryptedBuildKey ||
      String(decryptedBuildKey).length !== 64 ||
      decryptedBuildKey === projectBuild?.buildKey?.encryptedKey
    ) {
      return (
        <EncryptedDataWarning
          type="build"
          isOwner={projectDetail.created_by === userId}
          styleRoot={{
            background: "transparent",
            left: 13,
          }}
        />
      )
    }
    const conversationKeys = !isUndefined(projectBuild.conversationKeys)
      ? projectBuild.conversationKeys
      : []

    const decryptedConversationKeys = conversationKeys.map(
      (conversationKey) => {
        let isDecrypted = false
        if (conversationKey.encryptedKey) {
          const decrypted = encryptionHelper.decrypt(
            projectKey,
            conversationKey.encryptedKey
          )
          if (
            decrypted !== conversationKey.encryptedKey &&
            String(decrypted).length === 64
          ) {
            isDecrypted = true
          }
        }

        return {
          conversationCode: conversationKey.conversationCode,
          isDecrypted,
        }
      }
    )
    const notDecryptedConversationKeys = decryptedConversationKeys.filter(
      (el) => !el.isDecrypted
    )
    if (notDecryptedConversationKeys.length) {
      return (
        <EncryptedDataWarning
          styleRoot={{
            background: "transparent",
            left: 13,
          }}
          conversationCodes={notDecryptedConversationKeys.map(
            (el) => el.conversationCode
          )}
        />
      )
    }
    return (
      <div className="h-full flex flex-col">
        <InfoDraftBuildCard
          projectBuild={projectBuild}
          isDeleteBuild
          onUpdateDataEditNameBuild={onUpdateDataEditNameBuild}
        />
        <div
          className={`grid grid-cols-1 ${
            sizeWindow.width > 1395
              ? "md:grid-cols-[684px_calc(100%_-_684px)]"
              : "md:grid-cols-[358px_calc(100%_-_358px)]"
          } h-full border-t-[1px] border-solid border-[#E4E4E4] overflow-hidden`}
        >
          <div className="pl-6 pt-6 pb-5 overflow-hidden">
            <ComponentBuildCard
              components={projectBuild.components}
              onRemoveComponent={onRemoveComponent}
              className={`grid-cols-auto-fill-min-310 ${
                sizeWindow.width > 1395 ? "md:grid-cols-2" : "md:grid-cols-1"
              }`}
            />
          </div>
          <div className="hidden md:flex flex-col pl-6 pb-6 overflow-hidden bg-white border-l border-[#E4E4E4]">
            <div className="h-full flex flex-col justify-between">
              <ListTypeBuildCard
                cpnTypeOptions={cpnTypeOptions}
                cpnTypeSelected={cpnTypeSelected}
                onChangeTypeSelected={onChangeTypeSelected}
                folderOptions={listFolders.map((folder) => {
                  return {
                    label: folder.name,
                    value: folder.id,
                  }
                })}
                folderSelected={folderSelected}
                onChangeFolderSelected={onChangeFolderSelected}
              />
              <div className="grow overflow-auto">
                <div
                  className="grid"
                  style={{
                    marginRight: 24,
                    gridTemplateColumns: `repeat(auto-fill,minmax(305px,1fr))`,
                    gridGap: 12,
                    padding: "5px 0px",
                  }}
                >
                  {originListComponents.map((el, index) => {
                    return (
                      <ComponentDetailInBuild
                        key={index}
                        item={el}
                        isActive={objActive[el.id]}
                        onSelectComponent={onSelectComponent}
                      />
                    )
                  })}
                </div>
              </div>
              {!checkPermissionPage({
                project: archiveProject.value,
                build: archiveBuild.value,
              }) ? (
                <FooterSelectComponentCard
                  availableComponents={Object.values(objActive).length}
                  selectAll={selectAll.value}
                  onChangeSelectedAll={onChangeSelectedAll}
                  onSubmitAddSelected={onSubmitAddSelected}
                  disabledButtonAdd={isDisabledButtonAdd}
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className=" flex flex-col w-full border-l border-[#E4E4E4] h-[calc(100_*_var(--vh)_-_52px)] md:h-[calc(100_*_var(--vh)_-_0px)] ">
      <PermissionProjectBuildPage.Provider
        value={{
          archiveProject: archiveProject.value,
          archiveBuild: isUndefined(isArchived)
            ? Boolean(projectBuild.is_archived)
            : isArchived,
          listStatus,
          viewOnlyShare: viewOnlyShare.value,
          status: projectBuild.status,
          tabMenu: MENU_TAB_BUILD.ADDITIONAL,
          projectBuild: projectBuild,
        }}
      >
        <div className="h-[48px] flex justify-between items-center ">
          <div
            className="flex md:hidden p-6"
            onClick={() => {
              showBuildDetailLayout.setValue(false)
            }}
          >
            <ArrowLeftIcon />
          </div>
          <Header
            projectDetail={projectDetail}
            projectBuild={projectBuild}
            updatedAtBuild={updatedAtBuild}
          />
        </div>
        {renderLayout()}
      </PermissionProjectBuildPage.Provider>
    </div>
  )
}

export default BuildDraft
